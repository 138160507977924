import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from '../services/local-storage-service.service';

@Component({
  selector: 'app-appbridge',
  templateUrl: './appbridge.component.html',
  styleUrls: ['./appbridge.component.sass']
})
export class AppbridgeComponent implements OnInit {
  @ViewChild('site') module!: ElementRef

 siteUrl!: string
  guid!: string

  constructor(   
    private route: ActivatedRoute,
    private localStorage: LocalStorageService
    
    ) { }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap
    this.guid = String(routeParams.get('appbridgeId'))
    this.localStorage.set('siteId', this.guid)
    this.siteUrl = this.localStorage.get('currentSitePath')
  }

}
