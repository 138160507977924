<div class="roadmap">


  <div class="roadmap-header">
    <div class="roadmap-wrapper" flexLayout="column">
      <h1>{{ roadmap.name }}</h1>
      <p *ngIf="roadmap.description">{{ roadmap.description }}</p>
    </div>
  </div>

  <ng-container *ngIf="roadmap.pageViewType == 'daily'">
    <roadmap-daily
      [roadmap]="roadmap"
      [taskLookup]="taskLookup"
      [roadmapReady]="roadmapReady"
      [objectives]="objectives">
    </roadmap-daily>
  </ng-container>

  <ng-container *ngIf="roadmap.pageViewType == 'weekly'">
    <roadmap-weekly
      [roadmap]="roadmap"
      [taskLookup]="taskLookup"
      [roadmapReady]="roadmapReady"
      [objectives]="objectives">
    </roadmap-weekly>
  </ng-container>

  <ng-container *ngIf="roadmap.pageViewType == 'custom'">
    <roadmap-custom
      [roadmap]="roadmap"
      [taskLookup]="taskLookup"
      [roadmapReady]="roadmapReady">
    </roadmap-custom>
  </ng-container>

</div>