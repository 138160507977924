<div id="delphire-task" *ngIf="task" (click)="onOpenClick(task)" class="{{ class }}"
  [ngClass]="{
    'text-only': task.type == 'None' || task.type == 'url',
    'delphire-task-completed': task.selfCompleted || getCompletionStatus(task) == 'complete',
    'md-padding': true }
  ">
  <div class="{{ class }}-info">

    <!-- GRID VIEW ITEM TYPE -->
    <div class="{{ class }}-type-header {{ class }}-item">
      <span class="{{ class }}-capitalize" *ngIf="task?.type == 'Resource' && task?.resource?.type != 'package'">{{ task.resource?.type }}</span>
      <span class="{{ class }}-capitalize" *ngIf="task?.type == 'Resource' && task?.resource?.type == 'package'">Module</span>
      <span class="{{ class }}-capitalize" *ngIf="task?.type == 'Course'">{{ getType(task) }}</span>

      <span *ngIf="
          task?.type != 'Resource' &&
          task?.type != 'Course' &&
          task.type != 'url'
        ">{{ task.type }}</span>
    </div>
    <!-- GRID VIEW ITEM TYPE -->

    <h4 *ngIf="task.name">{{ getName() }}</h4>
    <h4 *ngIf="!task.name && task.type != 'url'">Text</h4>
    <!-- Handle bad course builder data for Text Only Item -->
    <div *ngIf="task.type == 'url' || task.body" class="{{ class }}-item-description" [innerHtml]="task.body"></div>
    <div class="{{ class }}-description" *ngIf="task.description" [innerHtml]="getDescription()"></div>
    <div class="{{ class }}-details" *ngIf="task?.type != 'None'">

      <!-- ITEM TYPE -->
      <div class="{{ class }}-type {{ class }}-item">
        <ng-icon class="icon" name="{{ getTypeIcon(task) }}" size="24px"></ng-icon>
        <span class="{{ class }}-capitalize" *ngIf="task?.type == 'Resource' && task?.resource?.type != 'package'">{{ task.resource?.type }}</span>
        <span class="{{ class }}-capitalize" *ngIf="task?.type == 'Resource' && task?.resource?.type == 'package'">Module</span>
        <span class="{{ class }}-capitalize" *ngIf="task?.type == 'Course'">{{ getType(task) }}</span>

        <span *ngIf="
            task?.type != 'Resource' &&
            task?.type != 'Course' &&
            task.type != 'url'
          ">{{ task.type }}</span>
      </div>
      <!-- ITEM TYPE -->

      <!-- LIBRARAY ITEMS -->
      <div class="{{ class }}-library-items {{ class }}-item" *ngIf="task?.type == 'Library'">
        <div class="roadmap-chip">
          <span class="roadmap-library-items-icon">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 9.776c.112-.017.227-.026.344-.026h15.812c.117 0 .232.009.344.026m-16.5 0a2.25 2.25 0 0 0-1.883 2.542l.857 6a2.25 2.25 0 0 0 2.227 1.932H19.05a2.25 2.25 0 0 0 2.227-1.932l.857-6a2.25 2.25 0 0 0-1.883-2.542m-16.5 0V6A2.25 2.25 0 0 1 6 3.75h3.879a1.5 1.5 0 0 1 1.06.44l2.122 2.12a1.5 1.5 0 0 0 1.06.44H18A2.25 2.25 0 0 1 20.25 9v.776" />
            </svg>
          </span>
          <span>{{ getNumLibraryItems(task) }} item</span>
          <span *ngIf="getNumLibraryItems(task) > 1">s</span>
        </div>
      </div>
      <!-- LIBRARAY ITEMS -->

      <!-- CLOCK / DURATION -->
      <ng-container *ngIf="task.type != 'url' && task.duration != 0">
        <div *ngIf="
            task?.resource?.type != 'audio' &&
            task?.resource?.type != 'video' &&
            task.duration
          " class="{{ class }}-duration {{ class }}-item">
          <ng-icon name="HeroClock" size="18px"></ng-icon>
          {{ task.duration }}
          <span [innerHtml]="language.widget.minutes"></span>
        </div>
        <div *ngIf="
            (task?.resource?.type == 'audio' && task.duration) ||
            (task?.resource?.type == 'video' && task.duration)
          " class="{{ class }}-duration {{ class }}-item">
          <ng-icon name="HeroClock" size="15px"></ng-icon>
          {{ task.duration | timeFormat }}
        </div>
      </ng-container>
      <!-- /CLOCK / DURATION -->

      <!-- PROGRESS -->
      <!-- PROGRESS: basic items from resources-->
      <div class="{{ class }}-progress {{ class }}-item"
        *ngIf="trackProgress(task) && !trackCourseProgress(task) && task.type !== 'None' && task.type !== 'url' && task.type !== 'External Link' && getCompletionStatus(task) != 'start'">
        <div class="roadmap-chip roadmap-chip-has-progress" *ngIf="getCompletionStatus(task) == 'resume'">
          {{ getCompletionPercent(task) }}% complete
        </div>
        <div class="roadmap-chip roadmap-chip-is-completed" *ngIf="getCompletionStatus(task) == 'complete'">
          Completed
        </div>
      </div>

      <!-- PROGRESS: courses NON-ZIP-->
      <div class="{{ class }}-progress {{ class }}-item" *ngIf="trackCourseProgress(task) && trackCourseType(task) && getCompletionStatus(task) != 'start'">
        <div class="roadmap-chip roadmap-chip-has-progress" *ngIf="getCompletionStatus(task) == 'resume'">
          {{ getCompletionPercent(task) }}% complete
        </div>
        <div class="roadmap-chip roadmap-chip-is-completed" *ngIf="getCompletionStatus(task) == 'complete'">
          Completed
        </div>
      </div>
      <!-- /PROGRESS -->

      <!-- PROGRESS: courses - ZIP: IN PROGRESS ONLY-->
      <div class="{{ class }}-progress {{ class }}-item" *ngIf="trackCourseProgress(task) && showInProgress(task) && getCompletionStatus(task) != 'start'">
        <div class="roadmap-chip roadmap-chip-has-progress" *ngIf="getCompletionStatus(task) == 'resume'">
          In Progress
        </div>
        <div class="roadmap-chip roadmap-chip-is-completed" *ngIf="getCompletionStatus(task) == 'complete'">
          Completed
        </div>
      </div>
      <!-- /PROGRESS -->

      <!-- SELF COMPLETE -->
      <div class="{{ class }}-item" *ngIf="(task.selfCompletion && task.selfCompletion!.enabled) || (taskAcknowledgement(task))">
        <button class="roadmap-chip roadmap-chip-self-complete"
          [ngClass]="{ completed: task.selfCompleted }" [disabled]="!shouldEnableSelfComplete(task)"
          (click)="selfComplete(task, $event)">
          <ng-icon *ngIf="task.selfCompleted" name="{{ getFavoriteIcon('shield', true) }}" size="15px"></ng-icon>
          {{ progressCopy4(task) }}
        </button>
      </div>
      <!-- /SELF COMPLETE -->

    </div>
  </div>
  <div class="task-spacer"></div>

  <!-- FAVORITE -->
  <div class="{{ class }}-favorite" >
    <div *ngIf="task.type != 'None'"
      matTooltip="{{ getFavoriteText(task) }}"
      matTooltipPosition="above"
      matTooltipHideDelay="50">
      <ng-icon matTooltipPosition="above" class="hand roadmap-not-favorite"
        size="20px" [hidden]="task.isFavorite" (click)="addFavorite(task, $event)" name="{{ getFavoriteIcon('favorite') }}">
      </ng-icon>
      <ng-icon matTooltipPosition="above" class="hand roadmap-is-favorite"
        size="20px" [hidden]="!task.isFavorite" (click)="addFavorite(task, $event)" name="{{ getFavoriteIcon('favorite') }}">
      </ng-icon>
    </div>
  </div>
  <!-- FAVORITE -->

  <!-- DEEP LINK -->
  <div class="{{ class }}-deeplink" title="Click to copy link" (click)="copyDeepLink(task, $event)" *ngIf="canDeepLink(task)">
    <div *ngIf="task.type != 'None'"
      class="{{ class }}-deeplink-click"
      matTooltip="Copy Link"
      matTooltipPosition="above"
      matTooltipHideDelay="50">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
      </svg>
    </div>
  </div>
  <!-- DEEP LINK -->
</div>