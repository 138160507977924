<div
  class="layout-detail-view"
  [ngStyle]="{
    'background-image': 'url(' + backgroundUrl + ')',
    'background-size': 'cover',
    'background-repeat': 'no-repeat',
    'background-position': 'top left',
    'height': '100%',
    'padding': '5px 2px 105px 2px'
  }"
>
  <router-outlet
    (activate)="outletActivated()"
    (deactivate)="outletDeactivated()"
  ></router-outlet>
  <div class="layout" *ngIf="backgroundUrl && shouldShowLayout">
    <div>
      <ng-container *ngFor="let layout of gridLayouts">
        <mat-grid-list
          *ngIf="sizeAbbreviation == layout.sizeAbbreviation"
          cols="{{ gridCols }}"
          rowHeight="{{ rowHeight }}"
          gutterSize="{{ gutterSize * 2 }}"
          class="delphire-layout delphire-layout-{{ sizeAbbreviation }}"
          style="max-width: 1366px; margin: 0 auto"
        >
          <mat-grid-tile
            class="widget"
            *ngFor="let w of layout.widgets; index as widgetIndex"
            rowspan="{{ w.h }}"
            colspan="{{ w.w }}"
          >
            <app-carousel
              *ngIf="w.type === 'Slideshow'"
              [widget]="w"
            ></app-carousel>
            <app-recommendations
              *ngIf="w.type === 'da-recommendations'"
              [widget]="w"
            ></app-recommendations>
            <!-- <app-recommendations
              *ngIf="widgetIndex === 9"
              [widget]="w"
            ></app-recommendations> -->
            <app-widget *ngIf="w.type != 'Slideshow' && w.type != 'da-recommendations'" [widget]="w"></app-widget>
          </mat-grid-tile>
        </mat-grid-list>
      </ng-container>
    </div>
  </div>
</div>
