import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AgendaComponent } from './agenda/agenda.component'
import { AppbridgeComponent } from './appbridge/appbridge.component'
import { AuthGuard } from './auth.guard'
import { CourseComponent } from './course/course.component'
import { DeepLinkComponent } from './deep-link/deep-link.component'
import { HomePageComponent } from './home-page/home-page.component'
import { LayoutDetailComponent } from './layout-detail/layout-detail.component'
import { LayoutComponent } from './layout/layout.component'
import { LibraryComponent } from './library/library.component'
import { ModuleComponent } from './module/module.component'
import { MyLearningsComponent } from './my-learnings/my-learnings.component'
import { NotificationsComponent } from './notifications/notifications.component'
import { PageNotFoundComponent } from './page-not-found/page-not-found.component'
import { QuizComponent } from './quiz/quiz.component'
import { RoadmapComponent } from './roadmap/roadmap.component'
import { ScormCompletionComponent } from './scorm-completion/scorm-completion.component'
import { SpaceComponent } from './space/space.component'


const childRoutes = [
  {
    path: 'notifications',
    component: NotificationsComponent
  },
  {
    path: 'my-learnings',
    component: MyLearningsComponent
  },
  {
    path: 'roadmap/:roadmapId',
    component: RoadmapComponent
  },
  {
    path: 'library/:libraryId',
    component: LibraryComponent
  },
  {
    path: 'favorites',
    component: LibraryComponent
  },
  {
    path: 'module/:moduleId',
    component: ModuleComponent
  },
  {
    path: 'appbridge/:appbridgeId',
    component: AppbridgeComponent
  },
  {
    path: 'course/:courseId',
    component: CourseComponent
  },
  {
    path: 'quiz/:quizId',
    component: QuizComponent
  },
  {
    path: 'agenda/:agendaId',
    component: AgendaComponent
  },
  {
    path: 'verbalizer/:verbalizerId',
    loadChildren: () => import('./verbalizer/verbalizer.module').then(m => m.VerbalizerModule)
  }
]
const routes: Routes = [
  {
    path: '',
    redirectTo: '/spaces',
    pathMatch: 'full'
  },
  {
    path: 'spaces',
    component: SpaceComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'link/:resourceId',
    component: DeepLinkComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'spaces/:spaceId/layouts',
    component: LayoutComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'spaces/:spaceId',
    component: SpaceComponent,
    children: childRoutes,
    canActivate: [AuthGuard]
  },
  {
    path: 'spaces/:spaceId/home',
    component: HomePageComponent,
    children: childRoutes,
    canActivate: [AuthGuard]
  },
  {
    path: 'spaces/:spaceId/layouts/:layoutId',
    component: LayoutDetailComponent,
    children: childRoutes,
    canActivate: [AuthGuard]
  },
  {
    path: 'scorm-completion',
    component: ScormCompletionComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 0]
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
