import { HttpClient } from '@angular/common/http'
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core'
import { getApp } from '@angular/fire/app'
import { collection, collectionSnapshots, getFirestore, query, where } from '@angular/fire/firestore'
import { getDownloadURL, getStorage, ref } from '@angular/fire/storage'
import { MatSidenavContainer } from '@angular/material/sidenav'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { AuthService } from '@auth0/auth0-angular'
import { Browser } from '@capacitor/browser'
import { Capacitor } from '@capacitor/core'
import { Observable, from } from 'rxjs'

import { tap } from 'rxjs/operators'

import { environment } from '../../environments/environment'
import { DelphireUtilities } from '../../utilities/delphire-utilities'
import { DelphireUser } from '../interfaces/delphire-user'
import { Layout } from '../interfaces/layout'
import { Space } from '../interfaces/space'
import { SearchComponent } from '../search/search.component'
import { DelphireSearchService } from '../services/delphire-search.service'
import { FirebaseService } from '../services/firebase.service'
import { LocalStorageService } from '../services/local-storage-service.service'
import { NavigationService } from '../services/navigation.service'
import { UserPreferencesService } from '../services/user-preference.service'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {
  @Output() snackBarEvent = new EventEmitter<boolean>()

  // @ViewChild('snav')
  @ViewChild('delphiresearch', { static: true }) delphiresearch: any
  verbose: boolean = environment.production ? false : true
  sidenav!: MatSidenavContainer
  language: any = environment.appLanguage
  version: string = environment.version
  notifications: any[] = []
  user: DelphireUser = { user: {} }
  layouts: Layout[] = []
  spaces: Space[] = []
  space: Space | undefined
  mobileSearch: boolean = false
  isHomePage: boolean = false
  searchHasResults: boolean = false

  @ViewChild('basicSearch') TheBasicSearchComponent: SearchComponent;

  constructor(
    private navigation: NavigationService,
    private localStorage: LocalStorageService,
    private fbS: FirebaseService,
    public userPreferenceService: UserPreferencesService,
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private searchService: DelphireSearchService,

    private httpClient: HttpClient
  ) {}

  copyLink(): void {
    console.log('copy link')
    this.snackBarEvent.emit(true)
  }
  getSpaceName(): string {
    if (this.space) {
      return DelphireUtilities.normalizeName(this.space?.name)
    } else {
      return ''
    }
  }

  checkSpace(): boolean {
    return this.router.url.indexOf("spaces/") > -1
  }

  selectSpace(space: any): void {
    if (space) {
      const spaceUrl: string = DelphireUtilities.normalizeName(space.name)
      if (environment.homePage.indexOf(spaceUrl) > -1) {
        this.router.navigate(['spaces/' + spaceUrl + '/home'])
      } else {
        this.router.navigate(['spaces/' + spaceUrl + '/layouts/'])
      }
    }
    this.sidenav.close()
  }

  selectLayout(layout: Layout): void {
    this.localStorage.set('currentLayout', layout)
    if (this.space) {
      const spaceUrl: string = DelphireUtilities.normalizeName(this.space.name)
      this.router.navigate(['spaces/' + spaceUrl + '/layouts/' + layout.id])
    }

    this.sidenav.close()
  }

  logout(): void {
    this.localStorage.clear()
    if (Capacitor.isNativePlatform()) {
      const returnTo = `${environment.urlScheme}://${environment.authConfig.domain}/capacitor/${environment.urlScheme}/callback`
      this.auth
        .buildLogoutUrl({ returnTo })
        .pipe(
          tap((url) => {
            // Call the logout function, but only log out locally
            // Redirect to Auth0 using the Browser plugin, to clear the user's session
            this.auth.logout({ localOnly: true })
            Browser.open({ url: url })
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          })
        )
        .subscribe()
    } else {
      const origin = window.location.origin
      this.localStorage.clear()
      this.auth.logout({ returnTo: origin})
      // window.location.reload()
    }
  }

  goBack(): void {
    if (window.location.href.includes('/module/')) {
      let currentLayout = this.localStorage.get('currentLayout');
      if (currentLayout) {
        if (this.space) {
          const spaceUrl: string = DelphireUtilities.normalizeName(this.space.name)
          this.router.navigate(['spaces/' + spaceUrl + '/layouts/' + currentLayout.id])
        }
      }
    } else {
      if(this.layouts.length === 1){
        this.router.navigate(['spaces'])
      } else {
        this.navigation.goBack()
      }
    }
  }
  goHome(): void {
    this.TheBasicSearchComponent.clearSearch()
    
    if (this.space?.name) {
      const spaceName = DelphireUtilities.normalizeName(this.space?.name)
      if (environment.homePage.indexOf(spaceName) > -1) {
        this.router.navigate(['spaces/' + spaceName + '/home'])
      } else {
        this.router.navigate(['spaces/' + spaceName + '/layouts'])
      }
    }
  }

  showMobileSearch(): void {
    this.mobileSearch = true
    this.searchService.forceFocus(true)
  }

  getSpaceImage(name: string): Observable<string> {
    const refUrl: string = 'Logos/' + name + '.png'

    const app = getApp()
    const storage = getStorage(app)
    const imgRef = ref(storage, refUrl)
    return from(getDownloadURL(imgRef))
  }

  ngOnInit(): void {
    this.searchService.currentStatus.subscribe((status) => {
      if (!this.searchHasResults) {
        if (status === 'blurred') {
          this.mobileSearch = false
        }
      }
    })

    this.searchService.resultsVisible.subscribe((visibility) => {
      if (this.verbose) {
        console.log('%c[ visibility ]', 'color: yellow', visibility)
      }
      this.searchHasResults = visibility
    })

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const urlArr = event.url.split('/')
        if (urlArr[urlArr.length - 1] === 'home') {
          this.isHomePage = true
        } else {
          this.isHomePage = false
        }
      }
    })
    this.localStorage.getItem('delphireUser').subscribe((user) => {
      if (user) {
        this.user = user
        if (this.user.tenants) {
          this.spaces = this.user.tenants
        }
      }
    })
    this.localStorage.getItem('currentSpace').subscribe((space) => {
      if (space) {
        this.space = space
        if (this.space) this.space.imageUrl = this.getSpaceImage(space.name)
        const { databaseUrl } = space
        this.fbS
          .db(databaseUrl)
          .list(environment.firebasePaths.space + 'layouts')
          .subscribe((layouts) => {
            this.layouts = layouts
          })
      }
    })
    this.localStorage.changes$.subscribe((change: any) => {
      if (change.type === 'set' && change.key === 'currentSpace') {
        this.space = change.value
        if (this.space)
          this.space.imageUrl = this.getSpaceImage(change.value.name)
        const { databaseUrl } = change.value
        this.fbS
          .db(databaseUrl)
          .list(environment.firebasePaths.space + 'layouts')
          .subscribe((layouts) => {
            this.layouts = layouts
          })
      }
    })
    this.localStorage.changes$.subscribe((change: any) => {
      if (change.type === 'set' && change.key === 'delphireUser') {
        if (change.value) {
          this.user = change.value
          if (this.user.tenants) {
            this.spaces = this.user.tenants
          }
        }
      }
    })

    let device: string = 'web'
    if (Capacitor.isNativePlatform()) {
      device = 'ios'
    } else {
      device = 'web'
    }

    const app = getApp()
    const firestore = getFirestore(app)
    const notificationsRef = collection(firestore, 'notifications')
    const q = query(
      notificationsRef,
      where('uid', '==', this.user.user?.id),
      where('device', '==', device),
      where('read', '!=', true)
    )
    collectionSnapshots(q).subscribe((notifications) => {
      this.notifications = []
      notifications.forEach((note) => {
        const data = note.data()
        const id = note.id
        this.notifications.push({ ...data, id })
      })
    })
  }
}
