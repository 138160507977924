import { HttpClient, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AuthService } from '@auth0/auth0-angular'
// import { AzureKeyCredential, SearchClient } from '@azure/search-documents'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { environment } from 'src/environments/environment'
import { LocalStorageService } from './local-storage-service.service'


@Injectable({
  providedIn: 'root',
})

export class DelphireSearchService {

  authToken: string | null = null

  apiKey = 'DBxQJSGtYzIMo73mdHZGuweubuASGkyVaHbKZqRhHvAzSeArj6gO'
  azureSearchUrl = 'https://modalai-dev-searchservice001.search.windows.net/indexes/modal_lexicon_analytics_index?api-version=2023-11-01'

  // TheSearchClient = new SearchClient(this.azureSearchUrl, "", new AzureKeyCredential(this.apiKey))

  public getApiToken(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.auth.getAccessTokenSilently().subscribe((token) => {
        this.authToken = token
        resolve()
      })
    })
  }


  // async SearchAzureTest(params: any): Promise<any[]> {

  //   // const body = {
  //   //   "count": true,
  //   //   "skip": 0,
  //   //   "top": 50,
  //   //   "searchMode": "any",
  //   //   "queryType": "simple",
  //   //   "facets": [
  //   //     "metadata_additionalMetadata_type,count:5,sort:count",
  //   //     "metadata_additionalMetadata_category,count:5,sort:count",
  //   //     "metadata_additionalMetadata_tenantId,count:5,sort:count"
  //   //   ],
  //   //   "search": params.query
  //   // }

  //   // const searchResults = await this.TheSearchClient.search(params.query)
    
  //   // queryType full and tilde ~ allows us to do a fuzzy search

  //   const searchResults = await this.TheSearchClient.search(
  //     params.query + "~", 
  //     {
  //       queryType: 'full',
  //       searchMode: 'any',
  //       searchFields: ['title', 'metadata_additionalMetadata_description']
  //     }
  //   )

  //   let searchResultsToReturn: any[] = []

  //   for await (const result of searchResults.results) {
  //     console.log(result);
  //     searchResultsToReturn.push(result.document)
  //   }

  //   if (searchResultsToReturn.length <= 0) {
  //     console.log('%c No search results', 'color:red')
  //   }

  //   return searchResultsToReturn
  // }

  async getRequest(
    params: any
  ): Promise<Observable<HttpResponse<any>>> {

    console.log('%c SEARCH PARAMS:', 'color:cyan');
    console.log(params);

    const tenantId = this.localStorage.get('currentSpace').id

    await this.getApiToken();

    const url = environment.searchApiUrl + '?search=' + params.query + '&fetchAll=true' + '&tenantId=' + tenantId
    
    const headers = {
      'Authorization': 'Bearer ' + environment.searchApiBearerToken,
      'Content-Type': 'application/json',
    }

    // console.log('%c[ SEARCH  SERVICE ]', 'color: aqua', { params, search, url, headers })
    
    return this.http.get<any>(url, {
      headers: headers,
      observe: 'response',
      // body: body,
      responseType: 'json'
    })
      
  }

  public subject = new Subject<any>()

  public editStatusDetails: string = 'blurred'
  private statusSource = new BehaviorSubject(this.editStatusDetails)

  currentStatus = this.statusSource.asObservable()

  changeStatus(status: string) {
    this.statusSource.next(status)
  }

  public editFocusDetails: boolean = false
  private focusSource = new BehaviorSubject(this.editFocusDetails)

  forcedFocus = this.focusSource.asObservable()

  forceFocus(focus: boolean) {
    this.focusSource.next(focus)
  }

  public editResultsDetails: boolean = false
  private resultsSource = new BehaviorSubject(this.editResultsDetails)

  resultsVisible = this.resultsSource.asObservable()

  setResultsVisibility(visible: boolean) {
    this.resultsSource.next(visible)
  }

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService,
    private auth: AuthService,
  ) {}
}
