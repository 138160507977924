<nav aria-label="Pagination">
  <ul>
    <li>
      <button
        aria-label="Go To Previous Page"
        [attr.aria-disabled]="current === 1"
        [disabled]="current === 1"
        (click)="onPrevious()"
      >
      <!-- <ng-icon name="HeroChevronLeft" size="20px"></ng-icon> -->
      <ng-icon name="hero-chevron-left" size="20px"></ng-icon>
      </button>
    </li>
    <li *ngFor="let page of pages; index as i">
      <a
        *ngIf="page !== -1; else more"
        class="internal"
        [attr.aria-current]="page === current ? 'page' : null"
        [attr.aria-label]="
          page === current ? 'Current Page, Page ' + page : 'Go to Page ' + page
        "
        [class.current]="page === current"
        tabindex="0"
        (click)="onGoTo(page)"
        (keyup.enter)="onGoTo(page)"
        >{{ page }}</a
      >
      <ng-template #more>
        <a class="more"> ... </a>
      </ng-template>
    </li>
    <li>
      <button
        aria-label="Go To Next Page"
        [attr.aria-disabled]="current === total"
        [disabled]="current === total"
        (click)="onNext()"
      >
      <!-- <ng-icon name="HeroChevronRight" size="20px"></ng-icon> -->
      <ng-icon name="hero-chevron-right" size="20px"></ng-icon>
      </button>
    </li>
  </ul>
</nav>
