import { Injectable } from '@angular/core'
import { Capacitor } from '@capacitor/core'
import { getApp } from 'firebase/app'
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore'
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal'
import { environment } from 'src/environments/environment'
import { BridgeUser } from '../interfaces/bridge-user'
import { DelphireUser } from '../interfaces/delphire-user'
import { Resource } from '../interfaces/resource'
import { Space } from '../interfaces/space'
import { TrackableDocumentModalComponent } from '../trackable-document-modal/trackable-document-modal.component'
import { TrackableMultiMediaModalComponent } from '../trackable-multimedia-modal/trackable-multimedia-modal.component'
import { DelphireApiService } from './delphire-api.service'
import { OrganizationService } from './delphire-organization.service'
import { FirebaseService } from './firebase.service'
import { LocalStorageService } from './local-storage-service.service'
import { ProgressService } from './tracking/progress.service'
import { TrackService } from './tracking/track.service'

declare global {
  interface Window {
    on(eventName?: string, callback?: (data: any) => void): void

    emit(eventName?: string, data?: any): void

    webkit: any
  }
}

export class Bridge {

  bridgeWindow!: any
  on(eventName?: string, callback?: (data: any) => void): void {
    window.addEventListener('message', function (event) {
      if (Capacitor.isNativePlatform()) {
        console.log('%c [ event ] ', 'background: red', event);
        try {
          const data = JSON.parse(event.data)
          if (data.eventName === eventName) {
            if (eventName && callback) return callback(data.data)
          }
        } catch (error) {
          console.log('ERROR', error)
        }
      } else {
        if (event.data.eventName === eventName) {
          if (eventName && callback) return callback(event.data.data)
        }
      }
    })
  }

  emit(eventName?: string, data?: any): void {
    if (eventName && data)
      this.bridgeWindow.postMessage({ eventName, data }, '*')
  }

  constructor(private targetWindow: Window) {
    this.bridgeWindow = targetWindow
  }
}

@Injectable({
  providedIn: 'root'
})


export class DelphireBridgeService {
  modalRef!: MdbModalRef<TrackableDocumentModalComponent>
  async handleLink(params:any){

    console.log('PARAMS BRIDGE', params)

    const space:Space = this.localStorage.get('currentSpace')
    this.fbs
    .db(space.databaseUrl)
    .object(
      environment.firebasePaths.space +
        '/resources/' +
        params.id
    )
    .subscribe(
      (resource: Resource) => {

        console.log('%c OPENING RESOURCE:', 'color: yellow');
        console.log(resource);

        switch (resource.type) {
          case 'video':
            if (resource) {
              const trackingParams = {
                service: 'LinkService',
                action: 'open',
                target: {
                  type: resource.type.toLocaleLowerCase(),
                  id: resource?.id,
                  description: resource?.name
                }
              }
              // this.trackingService.addEvent(trackingParams, 'Portal')
              this.modalRef = this.modalService.open(
                TrackableMultiMediaModalComponent,
                {
                  data: { resource: resource },
                  modalClass: 'modal-xl modal-video',
                  animation: false
                }
              )
            }
            break;
          case 'document':
            if (resource) {
              const trackingParams = {
                service: 'LinkService',
                action: 'open',
                target: {
                  type: resource.type.toLocaleLowerCase(),
                  id: resource?.id,
                  description: resource?.name
                }
              }
              // this.trackingService.addEvent(trackingParams, 'Portal')
              this.modalRef = this.modalService.open(
                TrackableDocumentModalComponent,
                {
                  data: { resource: resource },
                  modalClass: 'modal-xl modal-document',
                  animation: false
                }
              )
            }
            break;
          default:
            break;
        }
      })


  }
  async apiData() {
    await this.api.getApiToken()
    const currentUser: DelphireUser = this.localStorage.get('delphireUser')
    const currentSpace: Space = this.localStorage.get('currentSpace')
    const token: string = this.api.authToken || 'Unauthorized'
    const scormApi: string = environment.scormEngine
    const resourceId: string = this.localStorage.get('moduleId')

    const bridgeUser: BridgeUser = {
      email: currentUser.user?.email || '',
      family_name: currentUser.user?.familyName || '',
      given_name: currentUser.user?.givenName || '',
      groupId: currentUser.groupId || '',
      organization: currentUser.organizationId || '',
      id: currentUser.user?.id || '',
      name: {
        first: currentUser.user?.givenName || '',
        last: currentUser.user?.familyName || ''
      },
      nickname: currentUser.userInfo?.name,
      picture: currentUser.userInfo?.picture,
      managerEmailAddress: currentUser.manager?.email || '',
      updated_at:
        currentUser.userInfo?.updatedAt ||
        currentUser.userInfo?.updated_at ||
        ''
    }
    
    bridgeUser.user = {
      email: currentUser.user?.email || '',
      family_name: currentUser.user?.familyName || '',
      given_name: currentUser.user?.givenName || '',
      groupId: currentUser.groupId || '',
      organization: currentUser.organizationId || '',
      id: currentUser.user?.id || '',
      name: {
        first: currentUser.user?.givenName || '',
        last: currentUser.user?.familyName || ''
      },
      managerEmailAddress: currentUser.manager?.email || '',
      nickname: currentUser.userInfo?.name,
      picture: currentUser.userInfo?.picture,
      updated_at:
        currentUser.userInfo?.updatedAt ||
        currentUser.userInfo?.updated_at ||
        ''
    }

    const params = {
      currentUser: bridgeUser,
      tenant: currentSpace,
      token: token,
      api: scormApi,
      resourceId: resourceId
    }
    // console.log('*********************params*********************', params)
    return params
  }
  init(): void {
    console.log('Delphire Init')
    this.default = new Bridge(window)
    this.default.on('request-api-data', () => {
      this.apiData().then((results) => {
        this.default.emit('delphire-api-data', results)
      })
    })
    this.default.on('request-subordinates', (props) => {
      const { user } = this.localStorage.get('delphireUser')
      let userId: string
      if(props) {
        userId = props.id
      } else {
        userId = user.id
      }
      console.log('%c[ orgs ]', 'color: aqua', this.organizations)
      // console.log('%c[ users ]', 'color: aqua', this.users)
      // let subs = this.users.lookup.byId[userId].subordinates
      // this.default.emit('on-subordinates', {subordinates: subs} )
    })
    this.default.on('form-get-progress', (props) => {
      const spaceId = this.localStorage.get('currentSpace').id
      const { user } = this.localStorage.get('delphireUser')
      const app = getApp()
      const firestore = getFirestore(app)
      const userDataRef = collection( firestore, 'userData/' + spaceId + '/progress' )
      // console.log("userDataRef****************", userDataRef);
      // console.log("user.id****************", user.id);
      // console.log("user.id****************", props.id);
      const q = query(
        userDataRef,
        where('userId', '==', user.id),
        where('resourceId', '==', props.id)
      )
      let resourceUserData: any
      getDocs(q).then((doc) => {
        console.log('%c[ doc.empty ]', 'color: aqua', doc.empty)
        if (!doc.empty) {
          // resourceUserData = doc.docs[0].data()
          interface ReturnData {
            [key: string]: any
          }
          
          let returnData:ReturnData = {}
          doc.docs.forEach((doc) => {
            if (doc.exists()) {
              let docData = doc.data()
              returnData[docData.eventId] = docData
            }
          })
          console.log('%c[ this.existingUserData ]', 'color: deeppink', returnData)
          this.default.emit('form-got-progress', { progress: returnData })
        } else {
          this.default.emit('form-got-progress', { progress: {} })
        }
      })
    })
    this.default.on('module-progress', (props) => {
      props.namespace = 'ModuleProgress'
      console.log('module-progress called from bridge', props);
      this.trackingService.addEvent(props)
    })
    this.default.on('open-resource', (props) => {
      const params = {
        type: 'resource',
        id: props.id
      }
      this.handleLink(params)
    })
  }

  default!: Bridge
  nameSpace: string = 'Delphire'
  constructor(
    private localStorage: LocalStorageService,
    private trackingService: ProgressService,
    private organizations: OrganizationService,
    // private users: UserService,
    private track: TrackService,
    private api: DelphireApiService,
    private modalService: MdbModalService,
    private fbs:FirebaseService
  ) {
    this.track.setResourceType('module')
    this.init()
  }
}
