import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { Location } from '@angular/common'
import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { getApp } from '@angular/fire/app'
import { getDownloadURL, getStorage, ref } from '@angular/fire/storage'
import { Router } from '@angular/router'
import * as _ from 'lodash'
import { Observable, Subject, from } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { DelphireUtilities } from '../../utilities/delphire-utilities'
import { Space } from '../interfaces/space'
import { LocalStorageService } from '../services/local-storage-service.service'


@Component({
  selector: 'app-space',
  templateUrl: './space.component.html',
  styleUrls: ['./space.component.sass']
})
export class SpaceComponent implements OnInit, OnDestroy {
  @Input()
  space!: Space
  spaces: Space[] = []
  currentScreenSize: string | undefined
  gridCols: number = 0
  destroyed = new Subject<void>()

  backgroundUrl: string | undefined
  displayNameMap = new Map([
    [Breakpoints.XSmall, 'XSmall'],
    [Breakpoints.Small, 'Small'],
    [Breakpoints.Medium, 'Medium'],
    [Breakpoints.Large, 'Large'],
    [Breakpoints.XLarge, 'XLarge']
  ])
  outletActivated(): void {
    this.backgroundUrl = '/images/background.jpg'
    console.log(this.backgroundUrl)
  }

  outletDeactivated(): void {
    this.backgroundUrl = undefined
  }
  selectSpace(space: string): void {
    const currentSpace = _.find(this.spaces, { id: space })
    if (currentSpace) currentSpace.imageUrl = undefined
    this.localStorage.set('currentSpace', currentSpace)
    if (currentSpace) {
      const spaceUrl: string = DelphireUtilities.normalizeName(
        currentSpace.name
      )
      if (environment.homePage.indexOf(spaceUrl) > -1) {
        this.router.navigate(['/spaces/' + spaceUrl + '/home'])
      } else {
        this.router.navigate(['/spaces/' + spaceUrl + '/layouts'])
      }
    }
  }
  getSpaceImage(name: string): Observable<string> {
    const refUrl: string = 'Logos/' + name + '.png'

    const app = getApp()
    const storage = getStorage(app)
    const imgRef = ref(storage, refUrl)
    return from(getDownloadURL(imgRef))
  }
  constructor(
    private localStorage: LocalStorageService,
    private location: Location,
    private router: Router,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.localStorage.getItem('delphireUser').subscribe((user) => {
      if (user) {
        const { tenants } = user
        this.spaces = []
        tenants.forEach((space: Space) => {
          space.imageUrl = this.getSpaceImage(space.name)
          this.spaces.push(space)
        })
      }
    })

    this.localStorage.changes$.subscribe((change: any) => {
      if (change.type === 'set' && change.key === 'delphireUser') {
        const { tenants } = change.value
        this.spaces = []
        tenants.forEach((space: Space) => {
          space.imageUrl = this.getSpaceImage(space.name)
          this.spaces.push(space)
        })
      }
    })
    if(this.spaces?.length === 1 && window.location.pathname === '/spaces'){
      this.selectSpace(this.spaces[0].id)
    }

    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge
      ])
      .pipe(takeUntil(this.destroyed))
      .subscribe((result) => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            this.currentScreenSize = this.displayNameMap.get(query) ?? 'Unknown'
            switch (this.currentScreenSize) {
              case 'XSmall':
                this.gridCols = 2
                break
              case 'Small':
                this.gridCols = 3
                break
              case 'Medium':
                this.gridCols = 4
                break
              case 'Large':
                this.gridCols = 4
                break
              case 'XLarge':
                this.gridCols = 4
                break
              default:
                break
            }
          }
        }
      })
  }
  ngOnDestroy() {
    this.destroyed.next()
    this.destroyed.complete()
  }
}
