import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core'
import { ActivatedRoute, Event as NavigationEvent, NavigationStart, Router } from '@angular/router'
import { environment } from '../../environments/environment'
import { Layout } from '../interfaces/layout'
import { Space } from '../interfaces/space'
import { DelphireBridgeService } from '../services/delphire-bridge.service'
import { DelphireLinkService } from '../services/delphire-link.service'
import { OrganizationService } from '../services/delphire-organization.service'
import { DelphireSearchService } from '../services/delphire-search.service'
import { LocalStorageService } from '../services/local-storage-service.service'


interface SearchResult {
  created_at: { raw: string },
  description: { raw: string, snippet: string },
  entity_id: { raw: string },
  entity_type: { raw: string },
  id: { raw: string },
  layout_id: { raw: string },
  layout_name: { raw: string, snippet: string },
  name: { raw: string, snippet: string },
  tenant_name: { raw: string },
  updated_at: { raw: string },
  _meta: { engine: string, id: string, score: number },
  tenantId: string,
  title: string,
  metadata: { additionalMetadata: { type: string } },
  webPath: string,
}

interface SearchMetaData {
  alerts: [],
  engine: { name: string, type: string },
  page: { current: number, size: number, total_pages: number, total_results: number },
  requestId: string,
  warnings: []
}

interface LinkParams {
  id?: string,
  resource?: { id?: string, name?: string, type?: string },
  route: ActivatedRoute,
  type: string
}

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.sass']
})

export class SearchComponent implements OnInit, AfterViewInit, OnDestroy {
  verbose: boolean = environment.production ? false : true
  canSearch = true
  currentSpace = {} as Space
  currentLayout = {} as Layout
  searchTerms = ''
  currentAlphabeticSort = 'asc'

  allSearchResults: Array<SearchResult> = []
  filteredSearchResults: Array<SearchResult> = []
  displayedSearchResults: Array<SearchResult> = []

  searchMetaData = {} as SearchMetaData
  searchOpen = false
  totalResults = "Searching for"
  totalPages = 1
  currentPage = 1
  selectedPage = 1
  perPage = 10
  currentSearchCategory = 'course'
  linkParams = {} as LinkParams

  @Output('onSearchFocus') onSearchFocus = new EventEmitter()
  @Output('onSearchBlur') onSearchBlur = new EventEmitter()

  @ViewChild('searchInput') searchInputField: ElementRef | any

  constructor(
    private localStorage: LocalStorageService,
    private bridge: DelphireBridgeService,
    private router: Router,
    private searchService: DelphireSearchService,
    private linkService: DelphireLinkService,
    private organizations: OrganizationService,
    // private users: UserService,
    private route: ActivatedRoute
    ) { }


  routerEvent = this.router.events.subscribe((event: NavigationEvent) => {
    if(event instanceof NavigationStart) {
      this.canSearch = event.url == '/spaces' || event.url == '/' ? false : true
    }
  })

  doSearch(page?: number): void {
    if(this.searchTerms) {

      this.searchService.setResultsVisibility(true)

      this.localStorage.getItem('currentSpace').subscribe((space: Space) => {
        this.currentSpace = space
      })

      this.localStorage.getItem('currentLayout').subscribe((layout: Layout) => {
        this.currentLayout = layout
      })

      this.currentPage = page ? page : this.currentPage

      const searchParams = {
        query: "" + this.searchTerms,
        page: {
          // size: 10,
          size: 1000,
          current: page ? page : 1
        },
        filters: {
          tenant_id: [this.currentSpace.id]
        }
      }

      if(this.verbose) { console.log('%cSEARCHING FOR:', 'color: yellow', this.searchTerms) }

      
      // this.searchService.SearchAzureTest(searchParams).then((rawSearchResults) => {
        
      //   this.allSearchResults = []
      //   for (let i = 0; i < rawSearchResults.length; i++) {
          
      //     let rawSearchData = rawSearchResults[i]

      //     console.log(rawSearchData);
      //     // console.log(rawSearchData.metadata_additionalMetadata_description)

      //     let NewSearchResult: SearchResult = {
      //       created_at: { raw: '' },
      //       description: rawSearchData.metadata_additionalMetadata_description,
      //       entity_id: rawSearchData.id,
      //       entity_type: { raw: rawSearchData.metadata_additionalMetadata_type },
      //       id: rawSearchData.id,
      //       layout_id: { raw: '' },
      //       layout_name: { raw: '', snippet: '' },
      //       name: rawSearchData.title,
      //       tenant_name: { raw: '' },
      //       updated_at: rawSearchData.updated,
      //       _meta: { engine: '', id: '', score: 0 },
      //       tenantId: '',
      //       title: rawSearchData.title,
      //       metadata: { additionalMetadata: { type: rawSearchData.metadata_additionalMetadata_type } },
      //       webPath: '',
      //     }

      //     this.allSearchResults.push(NewSearchResult)
      //   }

      //   this.filteredSearchResults = this.allSearchResults

      //   this.currentSearchCategory = 'course'

      //   this.filteredSearchResults.sort(function(a, b) {
      //     let textA = a.title.toUpperCase();
      //     let textB = b.title.toUpperCase();
      //     return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      //   });

      //   this.displayedSearchResults = this.paginate(1, this.perPage)

      //   if(this.verbose) { console.log('%cSEARCH RESULTS:', 'color: lime', this.allSearchResults) }

      //   this.totalResults = this.filteredSearchResults.length.toString()

      //   this.updateTotalPages()

      //   this.selectedPage = this.currentPage = 1
      // });


      this.searchService.getRequest(searchParams).then((results) => {
        results.subscribe((results) => {
          console.log('%c SEARCH RESULTS: ', 'color:cyan');
          console.log(results);

          this.allSearchResults = results.body.entities
          this.searchMetaData = results.body.meta

          const tenantId = this.localStorage.get('currentSpace').id
          
          console.log('%c tenantID: ', 'color:cyan')
          console.log(tenantId)
          
          this.allSearchResults = this.allSearchResults.filter(theResult => theResult.tenantId === tenantId)
          
          this.filteredSearchResults = this.allSearchResults

          this.currentSearchCategory = 'course'

          this.filteredSearchResults.sort(function(a, b) {
            let textA = a.title.toUpperCase();
            let textB = b.title.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          });

          this.displayedSearchResults = this.paginate(1, this.perPage)

          if(this.verbose) { console.log('%cSEARCH RESULTS:', 'color: lime', this.allSearchResults) }

          // if(this.verbose) { console.log('%cSEARCH METADATA:', 'color: lime', this.searchMetaData) }
          // for (let i = 0; i < this.allSearchResults.length; i++) {
          //   console.log(this.allSearchResults[i].metadata)
          // }
          // this.totalResults = String(this.searchMetaData.page.total_results)
          // this.totalResults = String(results.body.queryCount)
          this.totalResults = this.filteredSearchResults.length.toString()
          // this.totalPages = this.searchMetaData.page.total_pages
          this.updateTotalPages()
          // this.selectedPage = this.currentPage = this.searchMetaData.page.current
          this.selectedPage = this.currentPage = 1
        })
      }).catch((error) => {
        console.error('SEARCH ERROR:', error)
      })

      this.searchOpen = true
    }
  }

  viewItem(item: SearchResult): void {
    if(this.verbose) { console.log('%c[ VIEW SEARCH ITEM ]', 'color: aqua', item) }
    console.log('%c[ VIEW SEARCH ITEM ]', 'color: aqua', item)
    // const resourceTypes = ['audio', 'document', 'video', 'package', 'resource', 'externallink']
    // const itemType = item.entity_type.raw.toLowerCase()

    this.linkService.handleLink(
      {
        id: item.id,
        type: 'course'
      }
    )

    // window.open(item.webPath, '_blank')?.focus()

    // this.linkParams.route = this.route
    // this.linkParams.type = itemType

    // if(resourceTypes.includes(itemType)) {
    //   this.linkParams.resource = {}
    //   this.linkParams.resource.id = item.entity_id.raw
    //   this.linkParams.resource.name = item.name.raw
    //   this.linkParams.resource.type = itemType
    // } else {
    //   this.linkParams.id = item.entity_id.raw
    // }

    // if(this.verbose) { console.log('%c[ this.linkParams ]', 'color: yellow', this.linkParams) }

    // switch (itemType) {
    //   case "layout":
    //   case "slideshow":
    //     this.router.navigate(['/spaces/' + DelphireUtilities.normalizeName(this.currentSpace.name) + '/layouts/' + item.entity_id.raw])
    //     break

    //   default:
    //     this.linkService.handleLink(this.linkParams)
    //     break
    //   }

    //   this.clearSearch()
  }

  getSearchResultType(item: SearchResult): string {
    if (item.metadata && item.metadata.additionalMetadata) {
      let type: string = item.metadata.additionalMetadata.type
      switch (type) {
        case 'application/pdf':
          return 'Document'
        case 'application/zip':
          return 'Module'
        case 'video/mp4':
          return 'Video'
        default:
      }
    }

    return 'Other'
  }

  onFocusEvent(): void {
    this.searchService.changeStatus("focused")
  }

  onBlurEvent(): void {
    this.searchService.changeStatus("blurred")
  }

  searchCategoryChange(value: string): void {
    this.currentSearchCategory = value

    if (this.currentSearchCategory === 'course') {
      this.filteredSearchResults = this.allSearchResults
      this.displayedSearchResults = this.filteredSearchResults
      this.updateTotalPages()
      return
    }

    this.filteredSearchResults = this.allSearchResults.filter(
      theResult => theResult.metadata && theResult.metadata.additionalMetadata? theResult.metadata.additionalMetadata.type && theResult.metadata.additionalMetadata.type.toLowerCase() === value.toLowerCase() : false)

    this.updateTotalPages()

    this.sortSearchResultsAlphabetic()

    this.onGoToPage(1)
  }

  searchSortAlphabeticChange(): void {
    console.log('%c Toggle search alphabetical sort', 'color:red')
    if (this.currentAlphabeticSort === 'asc') {
      this.currentAlphabeticSort = 'desc'
    } else {
      this.currentAlphabeticSort = 'asc'
    }

    console.log(this.currentAlphabeticSort)

    this.sortSearchResultsAlphabetic()
  }

  pageSelectionChange(obj: {value: number}): void {
    this.doSearch(obj.value)
  }

  getAlphabeticSortIcon(): string {
    if (this.currentAlphabeticSort === 'asc') {
      return this.getTypeIcon('ArrowUp')
    } else {
      return this.getTypeIcon('ArrowDown')
    }
  }

  getTypeIcon(type: string): string {
    // console.log("TYPE OF ICON = ", type)
    switch (type) {
      case "Audio"         : return "audiotrack"        ; break
      case "Document"      : return "picture_as_pdf"    ; break
      case "Video"         : return "ondemand_video"    ; break
      case "Module"        : return "tv"                ; break
      case "Package"       : return "snippet_folder"    ; break
      case "Quiz"          : return "psychology"        ; break
      case "Fluency"       : return "fact_check"        ; break
      case "Course"        : return "library_add_check" ; break
      case "Roadmap"       : return "today"             ; break
      case "Resource"      : return "file_copy"         ; break
      case "External Link" : return "open_in_new"       ; break
      case "ExternalLink"  : return "open_in_new"       ; break
      case "Slideshow"     : return "burst_mode"        ; break
      case "Library"       : return "vertical_split"    ; break
      case "Layout"        : return "dashboard"         ; break
      case "Agenda"        : return "calendar_today"    ; break
      case "Verbalizer"    : return "voice_chat"        ; break
      case "ArrowDown"     : return "south"             ; break
      case "ArrowUp"       : return "north"             ; break
      case "Other"         : return "note"              ; break
      default              : return ""                  ; break
    }
  }

  clearSearch(): void {
    this.searchTerms = ''
    this.searchOpen = false
    this.searchService.setResultsVisibility(false)
    this.searchService.changeStatus("blurred")
  }

  onGoToPage(page: number): void {
    this.currentPage = page
    this.displayedSearchResults = this.paginate(this.currentPage, this.perPage)
  }

  onNextPage(page: number): void {
    this.currentPage = page + 1
    this.displayedSearchResults = this.paginate(this.currentPage, this.perPage)
  }

  onPreviousPage(page: number): void {
    this.currentPage = page - 1
    this.displayedSearchResults = this.paginate(this.currentPage, this.perPage)
  }

  paginate(currentPage: number, perPage: number): SearchResult[] {
    return [...this.filteredSearchResults.slice((currentPage - 1) * perPage).slice(0, perPage)]
  }

  sortSearchResultsAlphabetic() {
    if (this.currentAlphabeticSort === 'desc') {

      this.filteredSearchResults.sort(function(a, b) {
        let textA = a.title.toUpperCase();
        let textB = b.title.toUpperCase();
        return (textA < textB) ? 1 : (textA > textB) ? -1 : 0;
      })

      this.displayedSearchResults = this.paginate(this.currentPage, this.perPage)

    } else {
      
      this.filteredSearchResults.sort(function(a, b) {
        let textA = a.title.toUpperCase();
        let textB = b.title.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });

      this.displayedSearchResults = this.paginate(this.currentPage, this.perPage)
    }
  }

  updateTotalPages(): void {
    this.totalPages = Math.floor(this.filteredSearchResults.length / this.perPage)
    const remainder = this.filteredSearchResults.length  % this.perPage
    if (remainder > 0) {
      this.totalPages += 1
    }
  }

  getPaginationText(): string {
    // console.log('%c CURRENT PAGE', 'color:cyan')
    // console.log(this.currentPage + ' / ')
    // console.log(this.totalPages)
    let startingNumber = ((this.currentPage - 1) * 10) + 1
    let finalNumber = startingNumber + 9
    if (this.currentPage >= this.totalPages) {
      let remainder = this.filteredSearchResults.length  % this.perPage
      finalNumber = startingNumber + remainder - 1
    }
    return 'Showing '  + startingNumber + ' to ' + finalNumber + ' of ' + this.filteredSearchResults.length
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.searchService.forcedFocus.subscribe((focus => {
      if(this.verbose) { console.log('%c[ --------- DO FORCE FOCUS --------- ]', 'color: deeppink', focus) }

      this.searchInputField.nativeElement.focus()
      console.log('%c[ orgs ]', 'color: aqua', this.organizations)
      // console.log('%c[ users ]', 'color: aqua', this.users)
    }))
  }

  ngOnDestroy() {
    this.routerEvent.unsubscribe()
  }

}
