import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FirebaseApp, getApp } from '@angular/fire/app'
import {
  collectionGroup,
  doc,
  DocumentSnapshot,
  Firestore,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  where
} from '@angular/fire/firestore'
import { getDownloadURL, getStorage, ref } from '@angular/fire/storage'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ActivatedRoute, Router } from '@angular/router'
import { Group, LandingPage } from '@delphire/dto'
import _ from 'lodash'
import moment from 'moment'
import { SpinnerVisibilityService } from 'ng-http-loader'
import { from, fromEvent, Observable } from 'rxjs'
import { startWith } from 'rxjs/operators'
import { environment } from 'src/environments/environment'

import { DelphireUtilities } from '../../utilities/delphire-utilities'
import { DelphireUser } from '../interfaces/delphire-user'
import { Favorite } from '../interfaces/favorite'
import { ProgressData } from '../interfaces/progress-data'
import { Resource } from '../interfaces/resource'
import { Space } from '../interfaces/space'
import { Task } from '../interfaces/task'
import { UserInfo } from '../interfaces/user-info'
import { FirebaseService } from '../services/firebase.service'
import { GroupService } from '../services/group-service'
import { LandingPageService } from '../services/landing-page.service'
import { LocalStorageService } from '../services/local-storage-service.service'
import { ProgressService } from '../services/progress.service'
import { ResourceService } from '../services/resource.service'
import { UserPreferencesService } from '../services/user-preference.service'

export interface ProgressId extends ProgressData {
  docId: string
}

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.sass']
})
export class HomePageComponent implements OnInit {
  progressDiv!: ElementRef<HTMLDivElement>
  @ViewChild('inProgress', { static: false }) set content(
    progressDiv: ElementRef<HTMLDivElement>
  ) {
    this.progressDiv = progressDiv
    if (this.progressDiv) {
      fromEvent(this.progressDiv.nativeElement, 'scroll').subscribe((evt) => {
        const ele = evt.target as HTMLDivElement
        const scrollOffSet = ele.scrollLeft + ele.offsetWidth
        if (scrollOffSet === ele.scrollWidth) {
          if (this.totalDocs % this.pageSize === 0) this.getUsersProgress()
        }
      })
    }
  }

  totalDocs: number = 0
  progressLoaded: boolean = false
  backgroundUrl: string | undefined

  user!: UserInfo

  pageSize: number = 1000

  progressType: string = 'In Progress'

  selectedGroup: Group.Type | null

  lastDoc!: any

  windowWidth: number = window.innerWidth

  userEntity!: DelphireUser

  progressData: Observable<ProgressId[]> | undefined

  shouldShowHomePage: boolean = true

  favorites$!: Observable<Favorite[]>
  favoritesArray!: Favorite[]

  progressDataCleaned: any[] = []

  progress: ProgressId[] = []

  userTasksInProgress: Task[] = []

  event!: Event

  lastTaskInProgress: Task | undefined

  libraryTasksByKey: any = {}

  libraries: any[] = []

  librariesLoaded: boolean = false

  spaces: any[] = []

  landingPageSections!: LandingPage.Type

  app: FirebaseApp = getApp()

  firestore: Firestore = getFirestore(this.app)

  heroBackgroundUrl: string = '/brand/atp/assets/images/hero-bg-2880.png'

  collectionQuery: any

  verbose: boolean = environment.production ? false : true

  groups: Group.Type[] = [];

  selectedGroups: Group.Type[] = [];


  outletActivated(): void {
    this.shouldShowHomePage = false
  }

  outletDeactivated(): void {
    this.shouldShowHomePage = true
  }
  newTaskSelected(event: any): void {
    const { task } = event
    if (this.userPreferences.isBinary(task.type)) this.lastTaskInProgress = task
  }

  toggleProgress(): void {
    if (this.progressType === 'In Progress') {
      this.progressType = 'Completed'
      this.getUsersProgress().then(() => {
        this.progressDiv.nativeElement.scrollLeft = 0
      })
    } else {
      this.progressType = 'In Progress'
      this.getUsersProgress().then(() => {
        this.progressDiv.nativeElement.scrollLeft = 0
      })
    }
  }
  setProgress(newUser: boolean): void {
    if (newUser) this.userTasksInProgress = []
    const { id } = this.localStorage.get('currentSpace')
    this.progressDataCleaned = _.filter(
      this.progressDataCleaned,
      (p: { name: any }) => {
        return p.name
      }
    )

    //console.log('this.progressDataCleaned 1',this.progressDataCleaned)

    this.progressDataCleaned = _.filter(this.progressDataCleaned, (r) => {
      if (this.progressType === 'In Progress') {
        if (
          r.name === 'ACCOUNT MANAGEMENT: Customer Engagement Model - Execute'
        )
          console.log('In Progress', r)
        return r.completed === false
      } else {
        //console.log('Completed', r.completed)
        return r.completed
      }
    })

    //console.log('this.progressDataCleaned 2',this.progressDataCleaned)

    const filteredOutUserInfoProgress = _.filter(
      this.progressDataCleaned,
      (r: { userDataType: any }) => {
        return r.userDataType !== 'userInfo'
      }
    ) as Task[]

    //console.log('filteredOutUserInfoProgress',filteredOutUserInfoProgress)

    this.userTasksInProgress = filteredOutUserInfoProgress
    this.lastTaskInProgress = filteredOutUserInfoProgress[0]
    this.userTasksInProgress.shift()
    if (newUser) {
      const blankTask: Task = {
        id: ''
      }
      this.lastTaskInProgress = blankTask
    }

    //console.log('lastTaskInProgress',this.lastTaskInProgress)
    this.spinner.hide()
  }

  getSpaceImage(name: string): Observable<string> {
    const refUrl: string = 'Logos/' + name + '.png'

    const app = getApp()
    const storage = getStorage(app)
    const imgRef = ref(storage, refUrl)
    return from(getDownloadURL(imgRef))
  }

  async getResources() {
    //console.log('%c[ this.progressDataCleaned ]', 'color: lime', this.progressDataCleaned)
    for (const [index, prog] of this.progressDataCleaned.entries()) {
      const progressData: ProgressId[] = _.mapValues(
        _.groupBy(this.progress, 'resourceId'),
        (plist) =>
          plist.map((p) => {
            if (p.resourceId === prog.resourceId) {
              return p
            } else {
              return p
            }
          })
      )[prog.resourceId]

      const { userDataType } = prog
      let node: string = 'resources'
      if (userDataType === 'quizprogress') {
        node = 'quizzes'
      }

      const downloadUrl = await this.resourceService.getDownloadUrl(
        prog.resourceId
      )
      const docRef = doc(this.firestore, node + '/' + prog.resourceId)
      getDoc(docRef).then((doc) => {
        if (doc.exists()) {
          const resource: Resource = doc.data() as Resource
          const { id } = resource as Resource
          resource.payload = prog.payload
          let item: Task = {
            id: id ? id : '',
            resource: resource as Resource,
            name: resource.name,
            type: resource.type,
            completionDate: moment(prog.createdAt).toISOString(),
            isFavorite: false,
            downloadUrl: downloadUrl
          }
          if (resource.type) {
            item = this.progressService.updateProgress(
              item,
              progressData,
              resource.type
            )
            if (item.completed) {
              item.percentage = 100
            }
          }
          const mergedItem = { ...prog, ...item }
          this.progressDataCleaned.splice(index, 1, mergedItem)
        }
        if (this.progressDataCleaned.length === index + 1)
          this.setProgress(false)
      })
    }
  }

  async getUsersProgress(): Promise<void> {
    // this.progressDataCleaned = []
    // this.progress = []
    const { user } = this.localStorage.get('delphireUser')

    this.spinner.show()

    const app = getApp()

    const firestore = getFirestore(app)
    const progressCol = collectionGroup(firestore, 'progress')

    //console.log('%c[ this.lastDoc ]', 'background-color: cyan', this.lastDoc)

    if (this.lastDoc) {
      this.collectionQuery = query(
        progressCol,
        where('userId', '==', user.id),
        orderBy('createdAt', 'desc'),
        // startAfter(this.lastDoc),
        limit(this.pageSize)
      )
    } else {
      this.collectionQuery = query(
        progressCol,
        where('userId', '==', user.id),
        orderBy('createdAt', 'desc'),
        limit(this.pageSize)
      )
    }

    // const data = collectionData(this.collectionQuery,{idField:'docId'})
    // data.pipe(
    //   map(filterProgressData)
    // )

    const docs = await getDocs(this.collectionQuery)
    //console.log('%c[ doc size ]', 'color: yellow', docs.size)
    //console.log('%c[ docs ]', 'color: yellow', docs)
    if (docs.size === 0) {
      // console.log('%c[ docs ]', 'color: yellow', 'Got here')
      this.progressDataCleaned = []
      return this.setProgress(true)
    }
    this.lastDoc = docs.docs[docs.docs.length - 1] as DocumentSnapshot
    this.totalDocs = this.totalDocs + docs.size

    docs.forEach((doc) => {
      const data = doc.data() as ProgressData
      const docId = doc.id

      this.progress.push({ ...data, docId } as ProgressId)

      if (this.progress.length === this.totalDocs) {
        const cleanData: ProgressData[] = this.progress.filter((d) => {
          return d.resourceId && DelphireUtilities.isGuid(d.resourceId)
        })

        this.progressDataCleaned = _.uniqBy(cleanData, 'resourceId')
        this.getResources()
      }
    })
  }

  //?===================
  getFavorites(): void {
    if (this.verbose) {
      console.log(
        '%c[ home-page.component : getFavorites() ]',
        'background-color: #336699; color: white; font-size: 16px; color: white'
      )
    }

    this.favoritesArray = this.userPreferences.favorites

    this.userPreferences.favorites$
      .pipe(startWith(this.userPreferences.favorites$))
      .subscribe(
        (favorites) => {
          if (this.verbose) {
            console.log(
              '%c[ getFavorites() : userPreferences.favorites$.subscribe ]',
              'background-color: #447700; color: white; font-size: 16px; color: white'
            )
          }

          this.favorites$ = favorites as Observable<Favorite[]>
          this.favoritesArray = this.userPreferences.favorites
        },
        (error) => {
          console.error(error)
        },
        () => {
          console.log('COMPLETE NO FAVORITES TO OBSERVE')
        }
      )
  }

  async getLibrary(id: string) {
    const libRef = doc(getFirestore(), 'libraries', id)
    const library = await getDoc(libRef)
    if (library.exists()) {
      const items: any = library.data().items
      const tasks: Task[] = []
      for (const item of Object.values(items)) {
        const anyItem: any = item
        anyItem.parameters.order = anyItem.order
        tasks.push(anyItem.parameters as Task)
      }
      this.libraries.push(library.data())
      this.libraryTasksByKey[id] = _.orderBy(tasks, 'order')
      return true
    } else {
      return false
    }
  }

  selectSpace(space: string): void {
    const currentSpace = _.find(this.spaces, { id: space })
    this.localStorage.set('currentSpace', currentSpace)
    if (currentSpace) {
      const spaceUrl: string = DelphireUtilities.normalizeName(
        currentSpace.name
      )
      if (spaceUrl === 'customer-engagement') {
        this.router.navigate(['/spaces/' + spaceUrl + '/home'])
      } else {
        this.router.navigate(['/spaces/' + spaceUrl + '/layouts'])
      }
    }
  }

  async getHomePageData(selectGroupId?: string) {
    let { user, groupId, tenants } = this.localStorage.get('delphireUser')
    if (selectGroupId) {
      groupId = selectGroupId;
    }
    this.spaces = []
    tenants.forEach((space: Space) => {
      space.imageUrl = this.getSpaceImage(space.name)
      this.spaces.push(space)
    })
    const { id } = this.localStorage.get('currentSpace')
    const userId = user.id
    const landingPageData = await this.landingPageService.getUserLandingPage({
      user: { id: userId },
      group: { id: groupId },
      tenant: { id: id }
    })
    if (landingPageData) {
      this.landingPageSections = landingPageData
      let sectionCount = 0
      const libsArr = _.filter(this.landingPageSections.sections, {
        type: 'library'
      })
      if (libsArr) {
        this.landingPageSections.sections.forEach((section) => {
          if (section.type === 'library') {
            this.getLibrary(section.libraryId).then((val) => {
              sectionCount++
              if (sectionCount === libsArr.length) this.librariesLoaded = true
            })
          }
        })
      }
    }

    const group = await this.groupService.getGroupById(groupId)
    const groups = await this.groupService.getGroups(id);
    if (groups?.length){
      this.groups = groups;
      this.selectedGroups = groups;
    }

    if (group) {
      this.selectedGroup = group;
    }

    this.getUsersProgress()
  }

  constructor(
    private localStorage: LocalStorageService,
    private fbs: FirebaseService,
    private progressService: ProgressService,
    private spinner: SpinnerVisibilityService,
    public userPreferences: UserPreferencesService,
    private landingPageService: LandingPageService,
    private groupService: GroupService,
    private router: Router,
    private route: ActivatedRoute,
    private resourceService: ResourceService,
    private snackbar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false
    console.log('home init')
    const currentPath: string | undefined = _.last(
      this.router.url.split('/')
    )?.toString()
    if (currentPath && currentPath !== 'home') {
      console.log('supposed to return')
      return
    }
    this.backgroundUrl = '/images/atp-gene-com-layout-bg.jpg'
    this.localStorage.getItem('delphireUser').subscribe((user) => {
      if (user) {
        this.userEntity = user
        this.user = user.userInfo
        this.userPreferences.showDisclaimerModal().then(() => {
          this.getFavorites()
          this.getHomePageData()
        })
      }
    })

    this.route.params.subscribe((params) => {
      if (params.spaceId) {
        const currentSpace: Space = this.localStorage.get('currentSpace')
        const spaceName: string = DelphireUtilities.normalizeName(
          currentSpace.name
        )
        if (params.spaceId !== spaceName) {
          const user: DelphireUser = this.localStorage.get('delphireUser')
          if (user.tenants) {
            const tenants: Space[] = user.tenants
            tenants.forEach((tenant: Space) => {
              const name: string = DelphireUtilities.normalizeName(tenant.name)
              if (name === params.spaceId) {
                this.localStorage.set('currentSpace', tenant)
              }
            })
          }
        }
      }
    })
  }

  onKey(event: Event) {
    this.selectedGroups = this.search((event.target as HTMLInputElement).value);
  }

  search(value: string) {
    let filter = value.toLowerCase();
    return this.groups.filter(option => option.name.toLowerCase().startsWith(filter));
  }

  handleGroupSelect(value: string){
    this.localStorage.set('delphireUser',  {...this.localStorage.get('delphireUser'), groupId: value})
    this.getHomePageData(value);
    this.snackbar.open('YOUR CONTENT DISPLAY HAS BEEN CHANGED. SCROLL DOWN TO VIEW CHANGES.', '', {
      horizontalPosition: 'left',
      verticalPosition: 'top',
      duration: 5000,
      panelClass: 'group-panel'
    })
  }

}
