<nav class="roadmap-nav roadmap-nav-level-1 roadmap-nav-tabs">
  <div class="roadmap-wrapper" flexLayout="column">
    <div class="roadmap-wrapper-gutters">
      <div class="roadmap-navigate-text">Navigate</div>
      <div class="roadmap-levels">
        <div class="roadmap-levels-inner roadmap-levels-1">
          <mat-select *ngIf="levels && levels.length > 0" [(value)]="currentPrimaryId" id="roadmap-select-custom-level-1"
            (selectionChange)="onPrimaryChange()">
            <mat-option class="roadmap-select-option" *ngFor="let level of levels" [value]="level.id">{{ level.name }}
            </mat-option>
          </mat-select>
          <div class="roadmap-objectives roadmap-objectives-level-1 roadmap-objectives-custom" *ngIf="
              currentLevel
              && currentLevel.description
              && currentLevel.description.title != ''
            ">
            <h4>{{ currentLevel.description.title }}</h4>

            <ng-container *ngIf="currentLevel.description.additional">
              <p *ngFor="let additional of currentLevel.description.additional">
                {{ additional }}
              </p>
            </ng-container>
          </div>
        </div>
        <div class="roadmap-levels-inner roadmap-levels-2" *ngIf="roadmap.levels.type == 'double' && currentLevel">
          <mat-select id="roadmap-select-custom-level-2" [(value)]="currentSecondaryId" (selectionChange)="onSecondaryChange()">
            <mat-option class="roadmap-select-option" *ngFor="let level of sublevels" [value]="level.id">{{ level.name }}
            </mat-option>
          </mat-select>
          <div class="roadmap-objectives roadmap-objectives-level-2 roadmap-objectives-custom" *ngIf="
              currentSubLevel
              && currentSubLevel.description
              && currentSubLevel.description.title != ''
          ">
            <h4>{{ currentSubLevel.description.title }}</h4>
            <p *ngFor="let additional of currentSubLevel.description.additional">{{ additional }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

<!-- ----------------------------------------------- NAV LEVEL 2 ----------------------------------------------- -->

<!-- <ng-container *ngIf="roadmap.levels.type == 'double' && currentLevel"> -->

  <!-- ------------------------------ NAV LEVEL 2: CUSTOM (CHOOSE SUBLEVEL) ------------------------------ -->

  <!-- <mat-select id="roadmap-select-custom-level-2" [(value)]="currentSecondaryId" (selectionChange)="onSecondaryChange()">
    <mat-option class="roadmap-select-option" *ngFor="let level of sublevels" [value]="level.id">{{ level.name }}
    </mat-option>
  </mat-select> -->

  <!-- ------------------------------ OBJECTIVES LEVEL 2 (CUSTOM ONLY) ------------------------------ -->

  <!-- <div class="roadmap-objectives roadmap-objectives-level-2 roadmap-objectives-custom roadmap-border-bottom" *ngIf="
      currentSubLevel
      && currentSubLevel.description
      && currentSubLevel.description.title != ''
  ">
    <h3>{{ currentSubLevel.description.title }}</h3>
    <p *ngFor="let additional of currentSubLevel.description.additional">{{ additional }}</p>
  </div> -->

<!-- </ng-container> -->

<!-- ----------------------------------------------- TASKS ----------------------------------------------- -->

<div class="roadmap-content-box">
  <div class="roadmap-wrapper" flexLayout="column">
    <div class="roadmap-controls roadmap-controls-custom roadmap-wrapper-gutters">
      <div class="roadmap-filter-controls">
          <mat-form-field class="example-full-width">
            <mat-label>
              Filter Items by Keyword
            </mat-label>
            <input matInput (keyup)="applyFilter()" #input [(ngModel)]="filterValue">
            <div class="roadmap-filter-icon">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#59AAE9" class="w-6 h-6" width="14px" height="16px">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 13.5V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 9.75V10.5" />
              </svg>
            </div>
          </mat-form-field>
      </div>
    </div>
    <div class="roadmap-tasks roadmap-tasks-custom padded">
      <app-task *ngFor="let t of filteredTasks" [class]="'roadmap-item'" [task]="t"></app-task>
    </div>
  </div>
</div>