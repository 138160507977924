import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router'
import { SpinnerVisibilityService } from 'ng-http-loader'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

import { environment } from '../../environments/environment'
import { Grid } from '../interfaces/grid'
import { Layout } from '../interfaces/layout'
import { Widget } from '../interfaces/widget'
import { DelphireApiService } from '../services/delphire-api.service'
import { FirebaseService } from '../services/firebase.service'
import { LocalStorageService } from '../services/local-storage-service.service'
import { UserPreferencesService } from '../services/user-preference.service'

@Component({
  selector: 'app-layout-detail',
  templateUrl: './layout-detail.component.html',
  styleUrls: ['./layout-detail.component.sass']
})
export class LayoutDetailComponent implements OnInit, OnDestroy {
  layout: Layout = {
    layouts: undefined,
    widgets: [],
    sizeAbbreviation: null
  }
  currentScreenSize: string | undefined
  destroyed = new Subject<void>()
  displayNameMap = new Map([
    [Breakpoints.XSmall, 'XSmall'],
    [Breakpoints.Small, 'Small'],
    [Breakpoints.Medium, 'Medium'],
    [Breakpoints.Large, 'Large'],
    [Breakpoints.XLarge, 'XLarge']
  ])

  gridCols: number = 0

  sizeAbbreviation: string | null = null

  rowHeight: number = 0

  gutterSize: number = 0

  gridLayouts: Layout[] = []

  shouldShowLayout: boolean = true

  backgroundUrl: string | undefined
  layoutBackgroundUrl: string | undefined

  outletActivated(): void {
    this.shouldShowLayout = false
    this.backgroundUrl = '/images/background.png'
  }

  outletDeactivated(): void {
    this.shouldShowLayout = true
    this.backgroundUrl = this.layoutBackgroundUrl
  }
  deconstructLayout(layout: Layout): void {
    if (layout.grid) {
      const grid: Grid = layout.grid
      const { rowHeight, gutterSize } = grid
      this.rowHeight = rowHeight
      this.gutterSize = gutterSize
    }
    const layouts = layout.layouts
    if (layouts) {
      this.gridLayouts = []
      this.spinner.show()
      Object.keys(layouts).forEach((key) => {
        const temp: Layout = {
          layouts: undefined,
          widgets: [],
          sizeAbbreviation: null
        }
        temp.widgets = layouts[key]
        temp.sizeAbbreviation = key
        this.gridLayouts.push(temp)
      })
      let widgets: Widget[] = []
      for (const gridLayout in this.gridLayouts) {
        widgets = []
        if (this.gridLayouts[gridLayout].widgets) {
          this.gridLayouts[gridLayout].widgets.forEach((w: any) => {
            if (layout.widgets) {
              const widget: Widget = Object.assign({}, w, layout.widgets[w.i])
              widgets.push(widget)
            }
          })
          widgets.sort((a, b) => (a.x < b.x ? -1 : a.x > b.x ? 1 : 0))
          widgets.sort((a, b) => (a.y < b.y ? -1 : a.y > b.y ? 1 : 0))
          this.gridLayouts[gridLayout].widgets = widgets
        }
      }
    }
    setTimeout(() => this.spinner.hide(), 1000)
    this.layout = layout
  }
  constructor(
    private route: ActivatedRoute,
    private fbS: FirebaseService,
    private localStorage: LocalStorageService,
    private delphireAPI: DelphireApiService,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private userPreferences: UserPreferencesService,
    private spinner: SpinnerVisibilityService
  ) {}

  getLayouts(): void {
    const routeParams = this.route.snapshot.paramMap
    const layoutId = String(routeParams.get('layoutId'))
    const { databaseUrl } = this.localStorage.get('currentSpace')
    this.fbS
      .db(databaseUrl)
      .object(environment.firebasePaths.space + 'layouts/' + layoutId)
      .subscribe((layout) => {
        if (layout && layout.style.backgroundResourceId) {
          this.delphireAPI
            .postRequest('resources/getDownloadUrl', {
              id: layout.style.backgroundResourceId
            })
            .then((response) => {
              response.subscribe((url) => {
                this.layoutBackgroundUrl = url.body.result.url
                if (this.shouldShowLayout)
                  this.backgroundUrl = url.body.result.url
                this.deconstructLayout(layout)
              })
            })
        } else if (layout) {
          this.layoutBackgroundUrl = ''
          this.backgroundUrl = '/images/background.jpg'
          this.deconstructLayout(layout)
        }
      })
  }
  ngOnInit(): void {
    this.userPreferences.showDisclaimerModal()
    this.router.events.subscribe((event) => {
      if (event instanceof ActivationEnd) {
        if (event.snapshot.params.layoutId) {
          this.gridLayouts = []
          this.getLayouts()
        }
      }
    })
    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge
      ])
      .pipe(takeUntil(this.destroyed))
      .subscribe((result) => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            this.currentScreenSize = this.displayNameMap.get(query) ?? 'Unknown'
            switch (this.currentScreenSize) {
              case 'XSmall':
                this.gridCols = 2
                this.sizeAbbreviation = 'xxs'
                break
              case 'Small':
                this.gridCols = 4
                this.sizeAbbreviation = 'xs'
                break
              case 'Medium':
                this.gridCols = 5
                this.sizeAbbreviation = 'md'
                break
              case 'Large':
                this.gridCols = 12
                this.sizeAbbreviation = 'lg'
                break
              case 'XLarge':
                this.gridCols = 12
                this.sizeAbbreviation = 'lg'
                break
              default:
                break
            }
          }
        }
      })
    this.getLayouts()
  }

  ngOnDestroy() {
    this.destroyed.next()
    this.destroyed.complete()
  }
}
