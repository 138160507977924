import { Location } from '@angular/common'
import { HttpClient } from '@angular/common/http'
import { Injectable, NgZone } from '@angular/core'
import { getApp } from '@angular/fire/app'
import { getAuth, signInWithCustomToken } from '@angular/fire/auth'
import { child, get, getDatabase, ref } from '@angular/fire/database'
import {
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where
} from '@angular/fire/firestore'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router'
import { AuthService } from '@auth0/auth0-angular'
import { App } from '@capacitor/app'
import { Browser } from '@capacitor/browser'
import { Capacitor } from '@capacitor/core'
import { PushNotifications, Token } from '@capacitor/push-notifications'
import _ from 'lodash'
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal'
import { ConnectionService } from 'ng-connection-service'
import { Spinkit } from 'ng-http-loader'
import { Observable, Subject } from 'rxjs'
import { catchError, mergeMap, tap } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { DelphireUtilities } from '../../utilities/delphire-utilities'
import { DelphireUser } from '../interfaces/delphire-user'
import { Favorite } from '../interfaces/favorite'
import { ProgressData } from '../interfaces/progress-data'
import { Resource } from '../interfaces/resource'
import { Space } from '../interfaces/space'
import { Task } from '../interfaces/task'
import { TrackableDocumentModalComponent } from '../trackable-document-modal/trackable-document-modal.component'
import { TrackableMultiMediaModalComponent } from '../trackable-multimedia-modal/trackable-multimedia-modal.component'
import { ConfirmationDialogService } from './confirmation-dialog.service'
import { DelphireLinkService } from './delphire-link.service'
import { FirebaseService } from './firebase.service'
import { LocalStorageService } from './local-storage-service.service'
import { Logger } from './logger.service'
import { NavigationService } from './navigation.service'
import { ProgressService } from './progress.service'

@Injectable({
  providedIn: 'root'
})
export class DelphireInitializationService {
  public favorites$!: Observable<Favorite[]>
  timeout: number = 5

  async checkAuth() {
    return new Promise((resolve, reject) => {
      this.auth.isAuthenticated$.subscribe((authenticated) => {
        if (authenticated) {
          const lastLocation = this.localStorage.get('lastLocation')
          if (lastLocation) {
            if (lastLocation.indexOf('module') > -1) {
              const spaceId = lastLocation.split('/')[2]
              this.router.navigateByUrl('spaces/' + spaceId).then(() => {
                this.localStorage.remove('lastLocation')
                resolve(true)
              })
            } else {
              this.router.navigateByUrl(lastLocation).then(() => {
                this.localStorage.remove('lastLocation')
                resolve(true)
              })
            }
          } else {
            if (window.location.pathname.indexOf('module') > -1) {
              const spaceId = window.location.pathname.split('/')[2]
              this.router.navigateByUrl('spaces/' + spaceId).then(() => {
                this.localStorage.remove('lastLocation')
                resolve(true)
              })
            } else {
              resolve(true)
            }
          }
        } else {
          //if (document.referrer && document.referrer.indexOf('app.') > -1) {
          this.localStorage.set('lastLocation', window.location.pathname)
          //}
          if (Capacitor.isNativePlatform()) {
            this.auth
              .buildAuthorizeUrl()
              .pipe(
                mergeMap((url) => Browser.open({ url, windowName: '_self' }))
              )
              .subscribe()
          } else {
            this.auth.loginWithRedirect()
          }
        }
      })
    })
  }

  async checkSpace() {
    return new Promise((resolve, reject) => {
      const space: Space = this.localStorage.get('currentSpace')
      console.log('%c INIT SERVICE this.route', 'background: red', this.route)
      console.log('%c INIT SERVICE space', 'background: red', space)
      // eslint-disable-next-line no-debugger
      // debugger
      if(window.location.pathname.split('/')[1] == 'link') {
        resolve(true)
        // if(space) {
        //   resolve(true)
        // } else {
        //   const { tenants } = this.localStorage.get('delphireUser')
        //   const newSpace: Space = _.find(tenants, (t) => {
        //     return true
        //   })
        //   resolve(this.localStorage.set('currentSpace', newSpace))
        // }
      } else {
        if (!space) {
          const spaceId = window.location.pathname.split('/')[2]
          console.log('spaceId', spaceId)
          if (spaceId) {
            this.localStorage.changes$.subscribe((changes: any) => {
              if (changes.type === 'set' && changes.key === 'delphireUser') {
                const { tenants } = this.localStorage.get('delphireUser')
                const newSpace: Space = _.find(tenants, (t) => {
                  return DelphireUtilities.normalizeName(t.name) === spaceId
                })
                resolve(this.localStorage.set('currentSpace', newSpace))
              }
            })
          }
        } else {
          // if(window.location.pathname.split('/')[1] == 'link') {
          //   resolve(true)
          // } else {
          const spaceId = window.location.pathname.split('/')[2]
          const spaceUrl: string = DelphireUtilities.normalizeName(space.name)
          if (spaceId !== spaceUrl) {
            const { tenants } = this.localStorage.get('delphireUser')
            const newSpace: Space = _.find(tenants, (t) => {
              return DelphireUtilities.normalizeName(t.name) === spaceId
            })
            resolve(this.localStorage.set('currentSpace', newSpace))
          } else {
            resolve(true)
          }
          // }
        }
      }
    })
  }

  initialize(): Promise<boolean> {

    this.localStorage.set('requestedURL', window.location.pathname)

    return new Promise<boolean>((resolve, reject) => {
      this.loggedIn.subscribe(
        (loggedIn) => {
          if (loggedIn) {
            const space: Space = this.localStorage.get('currentSpace')
            this.localStorage.set('currentSpace2', space)

            this.router.events.subscribe((event) => {
              if (event instanceof ActivationEnd) {
                const spaceId = event.snapshot.params.spaceId
                if (spaceId) {
                  if (space) {
                    const spaceUrl: string = DelphireUtilities.normalizeName(
                      space.name
                    )
                    if (spaceId !== spaceUrl) {
                      const { tenants } = this.localStorage.get('delphireUser')
                      const newSpace: Space = _.find(tenants, (t) => {
                        return (
                          DelphireUtilities.normalizeName(t.name) === spaceId
                        )
                      })
                      if (newSpace)
                        this.localStorage.set('currentSpace', newSpace)
                    }
                  } else {
                    if (spaceId) {
                      const { tenants } = this.localStorage.get('delphireUser')
                      const newSpace: Space = _.find(tenants, (t) => {
                        return (
                          DelphireUtilities.normalizeName(t.name) === spaceId
                        )
                      })
                      if (newSpace)
                        this.localStorage.set('currentSpace', newSpace)
                    }
                  }
                }
              }
            })
            if (!space) {
              const spaceId = window.location.pathname.split('/')[2]
              if (spaceId) {
                const { tenants } = this.localStorage.get('delphireUser')
                const newSpace: Space = _.find(tenants, (t) => {
                  return DelphireUtilities.normalizeName(t.name) === spaceId
                })
                if (newSpace) this.localStorage.set('currentSpace', newSpace)
              }
            } else {
              const spaceId = window.location.pathname.split('/')[2]
              const spaceUrl: string = DelphireUtilities.normalizeName(
                space.name
              )
              if (spaceId !== spaceUrl) {
                const { tenants } = this.localStorage.get('delphireUser')
                const newSpace: Space = _.find(tenants, (t) => {
                  return DelphireUtilities.normalizeName(t.name) === spaceId
                })
                if (newSpace) this.localStorage.set('currentSpace', newSpace)
              }
            }
            const lastLocation = this.localStorage.get('lastLocation')
            if (lastLocation && window.location.pathname === '/') {
              console.log('lastLocation', lastLocation)

              this.localStorage.remove('lastLocation')
              window.location.href = lastLocation
            }
            return resolve(true)
          }
        },
        (error) => {
          console.log(error)
        }
      )

      this.connectionService.monitor().subscribe((isConnected) => {
        if (isConnected) {
          this.isConnected = true
          this.status = 'ONLINE'
        } else {
          this.isConnected = false
          this.status = 'OFFLINE'
        }
      })
      const callbackUri = `${environment.urlScheme}://${environment.authConfig.domain}/capacitor/${environment.urlScheme}/callback`
      if (Capacitor.isNativePlatform()) {
        // Request permission to use push notifications
        // iOS will prompt user and return if they granted permission or not
        // Android will just grant without prompting
        PushNotifications.requestPermissions().then((result) => {
          if (result.receive === 'granted') {
            // Register with Apple / Google to receive push via APNS/FCM
            PushNotifications.register()
          } else {
            // Show some error
          }
        })

        PushNotifications.addListener('registration', (token: Token) => {
          //console.log('Push registration success, token: ' + token.value)
          this.pushToken = token.value
        })

        PushNotifications.addListener('registrationError', (error: any) => {
          console.log('Error on registration: ' + JSON.stringify(error))
        })
      }
      // Use Capacitor's App plugin to subscribe to the `appUrlOpen` event
      App.addListener('appUrlOpen', ({ url }) => {
        console.log('url', url)
        // Must run inside an NgZone for Angular to pick up the changes
        // https://capacitorjs.com/docs/guides/angular
        this.ngZone.run(() => {
          if (url?.startsWith(callbackUri)) {
            // If the URL is an authentication callback URL..
            if (
              url.includes('state=') &&
              (url.includes('error=') || url.includes('code='))
            ) {
              console.log('URL CALLBACK', url)
              // Call handleRedirectCallback and close the browser
              this.auth
                .handleRedirectCallback(url)
                .pipe(mergeMap(() => Browser.close()))
                .subscribe(() => {
                  this.getProfile()
                  this.getIdToken()
                  this.signInToDelphire()
                })
            } else {
              Browser.close()
            }
          } else {
            let link = url.split('?')[1].split('&')[0]
            link = link.split(window.location.host)[1]
            if (link) {
              this.checkRouteForResource(link)
            }
          }
        })
      })

      this.titleService.setTitle(environment.appName)
      this.navigation.startSaveHistory()

      if (window.navigator.onLine) {
        this.isConnected = true
        this.status = 'ONLINE'
      }

      if (this.isConnected) {
        this.getProfile()

        this.getIdToken()

        this.signInToDelphire()

        this.checkRouteForResource()
      }
    })
  }

  getProfile(): void {
    console.log('got profile')
    this.auth.user$.subscribe((profile) => {
      this.profileJson = JSON.stringify(profile, null, 2)
    })
  }

  getIdToken(): void {
    console.log('got idToken')
    this.auth.idTokenClaims$.subscribe((token) => {
      this.idToken = JSON.stringify(token?.__raw)
    })
  }

  async handleSignInError(error: any) {
    console.log('SIGN IN ERROR', error.data.reason)
    const reason: string = error.data.reason
    const dialogOptions = {
      title: reason.toUpperCase(),
      message:
        'You username or email was not found. If you believe this is incorrect please contact an administrator.',
      cancelText: 'CANCEL',
      confirmText: 'OK',
      panelClass: 'warningDialog'
    }
    this.dialog.open(dialogOptions)

    this.dialog.confirmed().subscribe((confirmed) => {
      this.localStorage.clear()
      if (Capacitor.isNativePlatform()) {
        const returnTo = `${environment.urlScheme}://${environment.authConfig.domain}/capacitor/${environment.urlScheme}/callback`
        this.auth
          .buildLogoutUrl({ returnTo })
          .pipe(
            tap((url) => {
              // Call the logout function, but only log out locally
              console.log('WHAT IS THE URL :', url)
              this.auth.logout()
              // Redirect to Auth0 using the Browser plugin, to clear the user's session
              window.location.reload()
            })
          )
          .subscribe()
      } else {
        const origin = window.location.origin
        this.auth.logout({ returnTo: origin })
      }
    })
  }

  requestSignIn(url: string, data: any, headers: any) {
    return this.http
      .post<any>(url, data, { headers, withCredentials: true })
      .pipe(catchError(this.handleSignInError))
  }

  async getManager(division: string) {
    const app = getApp()
    const firebase = getDatabase(
      app,
      `https://${environment.firebaseConfig.iamId}.firebaseio.com`
    )
    const path =
      environment.firebasePaths.state +
      'managerOrganizationAssociation/organizationManager/' +
      division
    const dbRef = ref(firebase)
    const refObj = await get(child(dbRef, path))
    const data = refObj.val()
    if (data) {
      const userPath = environment.firebasePaths.state + 'users/byId/' + data
      const mgrObj = await get(child(dbRef, userPath))
      const manager = mgrObj.val()
      return manager
    } else {
      return null
    }
  }
  signInToDelphire(): void {
    console.log('Sign In')
    let baseUrl =
      environment.authConfig.audience

    if(Capacitor.isNativePlatform()) {
      baseUrl = environment.authConfig.audience
    }

    const apiUrl = `${baseUrl}/iam/signIn`
    this.auth
      .getAccessTokenSilently({ timeoutInSeconds: this.timeout })
      .subscribe(
        (token) => {
          this.accessToken = token
          const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
          }

          this.requestSignIn(apiUrl, {}, headers).subscribe(
            async (response: any) => {
              if (response.error) {
                return this.handleSignInError(response.error)
              } else {
                console.log('result', response)
                const {
                  firebaseAccessToken,
                  groupId,
                  organizationId,
                  search,
                  tenants,
                  user,
                  userInfo,
                  layouts
                } = response

                const delphireUser: DelphireUser = {
                  groupId,
                  organizationId,
                  search,
                  tenants,
                  user,
                  groupAssignments: user.groupAssignments,
                  organizationAssignments: user.organizationAssignments,
                  userInfo,
                  layouts
                }

                if (Capacitor.isNativePlatform()) {
                  delphireUser.manager = await this.getManager(organizationId)
                } else {
                  delphireUser.manager = await this.getManager(organizationId)
                }
                console.log(
                  '*********************delphireUser.manager***********************',
                  delphireUser.manager
                )

                this.localStorage.set('delphireUser', delphireUser)

                this.firebaseToken = firebaseAccessToken

                console.log('this.firebaseToken', firebaseAccessToken)

                if (Capacitor.isNativePlatform()) this.router.navigateByUrl('')

                signInWithCustomToken(getAuth(), firebaseAccessToken)
                  .then((credentials) => {
                    const { user } = credentials
                    this.loggedIn.next(true)
                    if (Capacitor.isNativePlatform()) {
                      if (user?.uid && this.pushToken != null) {
                        const fs = getFirestore()
                        const userDoc = doc(
                          fs,
                          'userFirebaseCloudMessageTokens/' + user.uid
                        )
                        console.log('pushToken', this.pushToken)

                        setDoc(userDoc, {
                          id: user.uid,
                          iosNotificationToken: this.pushToken
                        })
                          .then(() => {
                            console.log('Token written')
                          })
                          .catch((error) => {
                            console.error('Token Update Error', error)
                          })
                      }
                    }
                  })
                  .catch((error) => {
                    console.error('FIREBASE SIGN IN ERROR', error)
                  })

                return true
              }
            }
          )
        },
        (error) => {
          console.log('SignIn Error', error)

          // return
          // Failed Login Attempt
          //throw new Error("Sign In Error")

          const routerState = window.location.pathname
          if (Capacitor.isNativePlatform()) {
            this.signInToDelphire()
          } else {
            this.signInToDelphire()
            // this.localStorage.clear()
            // const origin = window.location.origin
            // this.auth.loginWithRedirect()
          }
        }
      )
  }

  async checkForResourceProgress(resource: Resource, type: string) {
    await this.checkAuth()
    await this.checkSpace()
    const progData: ProgressData[] = []
    const { user } = this.localStorage.get('delphireUser')
    const progressCollection = collectionGroup(getFirestore(), 'progress')
    const q = query(
      progressCollection,
      where('userId', '==', user.id),
      where('resourceId', '==', resource.id)
    )
    const docs = await getDocs(q)
    docs.forEach((doc) => {
      if (doc.exists()) {
        progData.push(doc.data())
      }
    })
    const item: Task = resource as Task
    return new Promise<Resource>((resolve, reject) => {
      // console.log(progData)
      const resourceWithProgress = this.progressService.updateProgress(
        item,
        progData,
        type
      )
      // console.log('resourceWithProgress', resourceWithProgress)
      resolve(resourceWithProgress)
    })
  }
  async getResource(id: string) {
    const docRef = doc(getFirestore(), 'resources/' + id)
    return await getDoc(docRef).then((doc) => {
      if (doc.exists()) {
        const resource = doc.data()
        return resource
      } else {
        return undefined
      }
    })
  }
  async checkRouteForResource(linkPath?: string) {
    const path =
      this.localStorage.get('lastLocation') || linkPath || this.location.path()
    const segments = path.split('/')
    console.log('path', path)
    this.localStorage.set('requestedPath', path)
    if (
      segments.includes('video') ||
      segments.includes('audio') ||
      segments.includes('document') ||
      segments.includes('module')
    ) {
      const type = segments[segments.length - 2]
      const id = segments[segments.length - 1]
      segments.splice(segments.length - 2, 2)
      const url = segments.join('/')

      const resource = await this.getResource(id)

      if (resource) {
        const resourceWithProgress = await this.checkForResourceProgress(
          resource,
          type
        )
        // console.log('resourceWithProgress', resourceWithProgress)
        // console.log('route', this.route)
        if (resourceWithProgress && type !== 'module') {
          this.router.navigateByUrl(url).then(() => {
            this.location.go(
              window.location.pathname +
                '/' +
                type +
                '/' +
                resourceWithProgress.id
            )
          })

          this.delphireLinkService.handleLink({
            resource: resourceWithProgress,
            route: this.route,
            type: type
          })
        } else {
          this.delphireLinkService.handleLink({
            resource: resourceWithProgress,
            route: this.route,
            type: resourceWithProgress.type
          })
        }
      }
    } else {
      await this.checkAuth()
      await this.checkSpace()
    }
  }
  title = 'delphire-angular'

  status: string = 'ONLINE'

  isConnected: boolean = true
  spinkit = Spinkit
  profileJson: string | null = null

  idToken: string | null = null

  loggedIn: Subject<boolean> = new Subject()

  accessToken: string | null = null

  firebaseToken: string | null = null

  pushToken: string | null = null

  modalRef!: MdbModalRef<
    TrackableDocumentModalComponent | TrackableMultiMediaModalComponent
  >

  constructor(
    public auth: AuthService,
    public http: HttpClient,
    public localStorage: LocalStorageService,
    private ngZone: NgZone,
    public location: Location,
    public logger: Logger,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    public firebaseService: FirebaseService,
    public navigation: NavigationService,
    private connectionService: ConnectionService,
    private modalService: MdbModalService,

    private dialog: ConfirmationDialogService,
    private delphireLinkService: DelphireLinkService,
    private progressService: ProgressService
  ) {}
}
