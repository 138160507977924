<div class="recommendations" fxFlex fxLayout="column">
  <!-- <div class="title">{{ widget?.parameters?.title }}</div> -->
  <div class="bottom-fade"></div>
  
  <div class="container-title-box">
    <div class="container-icon-and-title" [ngClass]="loading? 'loading' : ''">
      <div class="recommendations-img"></div>
      <h3>{{title}}</h3>
    </div>
    <div *ngIf="recommendationData != null && !loading" class="container-recommendation-description">
      <p>{{recommendationData.reason}}</p>
    </div>
  </div>
  <!-- <div class="nudge">
    <div *ngIf="nudges.title" class="nudge-title">{{ nudges.title }}</div>
    <div *ngIf="nudges.title" class="nudge-date">{{ today | date:'MM/dd/yyyy' }}</div>
    <div *ngIf="nudges.message" class="nudge-message">{{ nudges.message }}</div>
  </div> -->
  <div class="content">
    <!-- <div class="content-title">{{ widget?.parameters?.recommendationsText }}</div> -->
    <div *ngIf="!loading && recommendationData != null" class="container-scroll-here">
      <div class="container-scroll-text">
        <p class="text-scroll-here">{{recommendationData.scrollText}}</p>
        <p class="text-last-updated">{{recommendationData.lastUpdatedText}}</p>
      </div>
      <p class="text-scroll-here"></p>
      <div class="container-refresh-block">
        <img class="" src="/images/icons/icon-refresh.png" (click)="clickRefresh()" />
        <p>Refresh</p>
      </div>
    </div>
    <p *ngIf="loading" class="text-loading">Loading recommendations...</p>
    <!-- <div *ngFor="let recommendation of recommendations" (click)="handleLink(recommendation)" class="recommendation">
      <div class="recommendation-name">{{ recommendation.name }}</div>
      <div class="recommendation-description">{{ recommendation.description }}</div>
    </div> -->
    <div *ngIf="loading" class="container-recommendations-list">
      <div class="container-loading-recommendation-item">
        <div class="loading-bar long"></div>
        <div class="loading-bar short"></div>
      </div>
      <div class="container-loading-recommendation-item">
        <div class="loading-bar long"></div>
        <div class="loading-bar short"></div>
      </div>
      <div class="container-loading-recommendation-item">
        <div class="loading-bar long"></div>
        <div class="loading-bar short"></div>
      </div>
      <div class="container-loading-recommendation-item">
        <div class="loading-bar long"></div>
        <div class="loading-bar short"></div>
      </div>
      <div class="container-loading-recommendation-item">
        <div class="loading-bar long"></div>
        <div class="loading-bar short"></div>
      </div>
      <div class="container-loading-recommendation-item">
        <div class="loading-bar long"></div>
        <div class="loading-bar short"></div>
      </div>
      <div class="container-loading-recommendation-item">
        <div class="loading-bar long"></div>
        <div class="loading-bar short"></div>
      </div>
      <div class="container-loading-recommendation-item">
        <div class="loading-bar long"></div>
        <div class="loading-bar short"></div>
      </div>
    </div>
    <div *ngIf="!loading && recommendationData != null" class="container-recommendations-list">
      <div *ngFor="let recommendation of recommendationData.recommendations" (click)="clickItem(recommendation)" class="container-recommendation-item">
        <div class="container-course-info">
          <div class="recommendation-name">{{ recommendation.course }}</div>
          <img class="img-caret" src="/images/caret-right.png" />
        </div>
        <div class="container-recommendation-status">
          <div *ngIf='recommendation.progressStatus != ""' class="recommendation-status-block" [ngClass]="getStatusBlockClass(recommendation)">
            <p>{{ recommendation.progressStatus }}</p>
          </div>
        </div>
      </div>
      <div *ngIf="recommendationData.recommendations.length <= 0" class="container-no-recommendations">
        <p>No recommendations currently available.</p>
        <img class="" src="/images/no-recommendations.png" />
      </div>
    </div>
  </div>
</div>

<!-- <div class="recommendations">
  <div class="title">{{parameters.title}}</div>
  <div class="nudge">
    <div class="nudge-title">{{parameters.nudgesTitle}}</div>
    <div class="nudge-date">{{ today | date:'MM/dd/yyyy' }}</div>
    <div class="nudge-message">{{nudge.message}}</div>
  </div>
  <div class="content">
    <div class="content-title">{{parameters.recommendationsText}}</div>
    <div ng-repeat="recommendation in recommendations" ng-click="handleOpenLink(recommendation.product_id)" class="recommendation">
      <div class="recommendation-name">{{resources[recommendation.product_id].name}}</div>
      <div class="recommendation-description">{{resources[recommendation.product_id].description}}</div>
    </div>
  </div>
</div> -->