<div style="text-align: center; padding-top: 100px;">
  <div class="text">
    <div>Loading resource...</div>
    <div *ngIf="resource">Resource loaded!</div>
    <div *ngIf="resource" class="may-close">You may close this window or press the Home button above when finished.</div>
    <div *ngIf="resourceError" class="error">Sorry, that resource ID was not found or you are not registered.</div>
    <button class="popup" (click)="openLink()" *ngIf="resource">
      Click here if your popup blocker is stopping the resource.
    </button>
  </div>
</div>