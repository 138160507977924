import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import _ from 'lodash';
import { environment } from '../../environments/environment';
import { DelphireUser } from '../interfaces/delphire-user';
import { Layout } from '../interfaces/layout';
import { Resource } from '../interfaces/resource';
import { Space } from '../interfaces/space';
import { DelphireApiService } from '../services/delphire-api.service';
import { DelphireLinkService } from '../services/delphire-link.service';
import { FirebaseService } from '../services/firebase.service';
import { LocalStorageService } from '../services/local-storage-service.service';

@Component({
  selector: 'app-deep-link',
  templateUrl: './deep-link.component.html',
  styleUrls: ['./deep-link.component.scss']
})
export class DeepLinkComponent implements OnInit {

  layouts: Layout[] = []
  space!: Space
  resource!: Resource
  resourceError: boolean = false

  constructor(
    private router: Router,
    private localStorage: LocalStorageService,
    private fb: FirebaseService,
    private linkService: DelphireLinkService,
    private route: ActivatedRoute,
    private delphireAPI: DelphireApiService
  ) {}

  ngOnInit(): void {
    this.openLink()
  }

  // ------------------------------------------------------------------

  async openLink(): Promise<void> {
    const user:DelphireUser = this.localStorage.get('delphireUser')
    const requestedPath:string = this.localStorage.get('requestedPath')
    const requestedURL:string = this.localStorage.get('requestedURL')
    const requestedID = requestedPath.split('/')[2]
    this.space = this.localStorage.get('currentSpace')

    console.log('%c DEEP LINK COMPONENT', 'background: red')
    console.log('%c DEEP LINK COMPONENT user', 'background: red', user)
    console.log('%c DEEP LINK COMPONENT requestedURL', 'background: red', requestedURL)
    console.log('%c DEEP LINK COMPONENT requestedID', 'background: red', requestedID)
    if (user && user.tenants && user.tenants.length > 0) { console.log('%c DEEP LINK COMPONENT user.tenants[0].databaseUrl', 'background: red', user.tenants[0].databaseUrl) }
    if (this.space) { console.log('%c DEEP LINK COMPONENT this.space', 'background: red', this.space) } else { console.log('%c DEEP LINK COMPONENT this.space', 'background: red', 'undefined') }
    // console.log('%c DEEP LINK COMPONENT this.space.databaseUrl', 'background: red', this.space.databaseUrl)
    console.log('%c DEEP LINK COMPONENT environment.firebasePaths.space', 'background: red', environment.firebasePaths.space)
    console.log('%c DEEP LINK COMPONENT fullPath', 'background: red', environment.firebasePaths.space + 'resources/' + requestedID)

    if (user && user.tenants && user.tenants.length > 0) {

      try {
        this.fb
          // .db(this.space.databaseUrl)
          .db(user.tenants[0].databaseUrl)
          .object(environment.firebasePaths.space + 'resources/' + requestedID)
          .subscribe(async (data) => {
            const resource: Resource = data as Resource
            console.log('%c DEEP LINK COMPONENT resource', 'green: red', resource)
            this.resource = resource

            const { tenants } = this.localStorage.get('delphireUser')
            const newSpace: Space = _.find(tenants, (t) => {
              return true
            })
            this.localStorage.set('currentSpace', newSpace)
            console.log('%c DEEP LINK COMPONENT newSpace', 'background: red', newSpace)

            if(resource) {
              this.linkService.handleLink({
                type: resource.type,
                resource: resource,
                route: this.route
              })
            } else {

              console.log('%c DEEP LINK COMPONENT newSpace.id', 'background: green', newSpace.id)
              console.log('%c DEEP LINK COMPONENT requestedID', 'background: green', requestedID)
              console.log('%c DEEP LINK COMPONENT user.user.id', 'background: green', user.user.id)

              const link = await this.delphireAPI.postRequestPrisma(
                '/iam/getLaunchLink',
                {
                  tenantId: newSpace.id,
                  courseId: requestedID,
                  learnerId: user.user.id
                }
              )

              link.subscribe(async (res) => {
                console.log('res', res)

                if (res.body.error) {
                  console.log('%c DEEP LINK COMPONENT COURSE error', 'background: red', res.body.error)
                  this.resourceError = true
                } else {

                  this.resource = res.body as Resource

                  this.linkService.handleLink({
                    type: 'course',
                    id: requestedID,
                    route: this.route
                  })
                }
              })

            }
          })
        } catch (error) {
          console.log('%c DEEP LINK COMPONENT error', 'background: red', error)
          this.resourceError = true
        }
    }
  }

  // ------------------------------------------------------------------

}
