<div id="delphire-library" class="roadmap" [ngClass]="{ 'grid-view': gridMode }" >

  <div class="library-header">
    <div class="library-wrapper">
      <!-- * Library Name -->
      <h1 class="library-name" [innerHtml]="library.name"></h1>
      <!-- * Library Description -->
      <p class="library-description" *ngIf="library.description" [innerHtml]="library.description"></p>
      <div class="library-toggle">
        <input type="checkbox" (change)="toggleChange($event)" checked="checked" />
        <label></label>
        <div class="icon-gridview">
          <!-- <ng-icon name="HeroViewGrid" size="18px"></ng-icon> -->
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6A2.25 2.25 0 0 1 6 3.75h2.25A2.25 2.25 0 0 1 10.5 6v2.25a2.25 2.25 0 0 1-2.25 2.25H6a2.25 2.25 0 0 1-2.25-2.25V6ZM3.75 15.75A2.25 2.25 0 0 1 6 13.5h2.25a2.25 2.25 0 0 1 2.25 2.25V18a2.25 2.25 0 0 1-2.25 2.25H6A2.25 2.25 0 0 1 3.75 18v-2.25ZM13.5 6a2.25 2.25 0 0 1 2.25-2.25H18A2.25 2.25 0 0 1 20.25 6v2.25A2.25 2.25 0 0 1 18 10.5h-2.25a2.25 2.25 0 0 1-2.25-2.25V6ZM13.5 15.75a2.25 2.25 0 0 1 2.25-2.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-2.25A2.25 2.25 0 0 1 13.5 18v-2.25Z" />
          </svg>
        </div>
        <div class="icon-listview">
          <!-- <ng-icon name="HeroViewList" size="18px"></ng-icon> -->
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
          </svg>
        </div>
        <div class="label-grid">Grid</div>
        <div class="label-list">List</div>
      </div>
    </div>
  </div>

  <!-- <div class="roadmap-content-box" *ngIf="tasks && tasks.length > 0"> -->
  <div class="roadmap-content-box">
    <div class="roadmap-wrapper" flexLayout="column">
      <div class="roadmap-controls roadmap-controls-top">

        <div class="roadmap-filter-controls">
            <mat-form-field class="example-full-width">
              <mat-label>
                Filter Items by Keyword
              </mat-label>
              <input matInput (keyup)="applyFilter()" #input [(ngModel)]="filterValue">
              <div class="roadmap-filter-icon">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#59AAE9" class="w-6 h-6" width="14px" height="16px">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 13.5V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 9.75V10.5" />
                </svg>
              </div>
            </mat-form-field>
        </div>

        <div class="roadmap-pagination-controls">
          <div class="roadmap-pagination-controls-wrapper">
            <div class="roadmap-pagination-spacer"></div>

            <div class="roadmap-pagination-select">
              <mat-form-field>
                <mat-select id="roadmap-pagination-select" [(value)]="perPage" (selectionChange)="applyFilter()">
                  <mat-option class="roadmap-select-option" *ngFor="let num of paginationOptions" [value]="num">{{ num }} Items
                  </mat-option>
                </mat-select>
                <ng-icon name="HeroChevronDown" size="15px"></ng-icon>
              </mat-form-field>
            </div>

            <div class="roadmap-pagination-showing">
              <span>Showing</span>
              <span>{{ (currentPage - 1) * perPage + 1 }}</span>
              <span>to</span>
              <span>{{ currentPage * perPage > tasks.length ? tasks.length : currentPage * perPage }}</span>
              <span>of</span>
              <span>{{ tasks.length }}</span>
            </div>

            <div class="roadmap-pagination-main">
              <app-pagination
                [current]="currentPage"
                [total]="totalPages"
                (goTo)="onGoTo($event)"
                (next)="onNext($event)"
                (previous)="onPrevious($event)"
              ></app-pagination>
            </div>
          </div>
        </div>
      </div>
      <div class="roadmap-tasks padded">
        <app-task *ngFor="let t of tasksToDisplay" [class]="'roadmap-item'" [useTaskName]="false" [task]="t"></app-task>
      </div>
      <div class="roadmap-controls roadmap-controls-bottom">

        <div class="roadmap-filter-controls">
            <mat-form-field class="example-full-width">
              <mat-label>
                Filter Items by Keyword
              </mat-label>
              <input matInput (keyup)="applyFilter()" #input [(ngModel)]="filterValue">
              <div class="roadmap-filter-icon">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#59AAE9" class="w-6 h-6" width="14px" height="16px">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 13.5V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 9.75V10.5" />
                </svg>
              </div>
            </mat-form-field>
        </div>

        <div class="roadmap-pagination-controls">
          <div class="roadmap-pagination-controls-wrapper">
            <div class="roadmap-pagination-spacer"></div>

            <div class="roadmap-pagination-select">
              <mat-form-field>
                <mat-select id="roadmap-pagination-select" [(value)]="perPage" (selectionChange)="applyFilter()">
                  <mat-option class="roadmap-select-option" *ngFor="let num of paginationOptions" [value]="num">{{ num }} Items
                  </mat-option>
                </mat-select>
                <ng-icon name="HeroChevronDown" size="15px"></ng-icon>
              </mat-form-field>
            </div>

            <div class="roadmap-pagination-showing">
              <span>Showing</span>
              <span>{{ (currentPage - 1) * perPage + 1 }}</span>
              <span>to</span>
              <span>{{ currentPage * perPage > tasks.length ? tasks.length : currentPage * perPage }}</span>
              <span>of</span>
              <span>{{ tasks.length }}</span>
            </div>

            <div class="roadmap-pagination-main">
              <app-pagination
                [current]="currentPage"
                [total]="totalPages"
                (goTo)="onGoTo($event)"
                (next)="onNext($event)"
                (previous)="onPrevious($event)"
              ></app-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>