import { HttpClient } from '@angular/common/http'
import { Component, Input, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { HTTP } from '@awesome-cordova-plugins/http/ngx'
import moment from 'moment'
import { environment } from 'src/environments/environment'
import { Widget } from '../interfaces/widget'
import { DelphireApiService } from '../services/delphire-api.service'
import { DelphireLinkService } from '../services/delphire-link.service'
import { FirebaseService } from '../services/firebase.service'
import { LocalStorageService } from '../services/local-storage-service.service'


interface RecommendationItem {
  course: string,
  courseId: string, 
  progressStatus: string,
  weight: number
}

interface RecommendationInfo {
  reason: string,
  recommendations: RecommendationItem[],
  scrollText?: string
  lastUpdatedText?: string
}

interface ResourceParams {
  [key: string]: any
}

interface Nudge {
  match_score?: number
  message?: string
  title?: string
}

@Component({
  selector: 'app-recommendations',
  templateUrl: './recommendations.component.html',
  styleUrls: ['./recommendations.component.sass']
})
export class RecommendationsComponent implements OnInit {
  @Input()
  widget: Widget | undefined
  verbose: boolean = environment.production ? false : true

  loading: boolean = true

  recommendationData: RecommendationInfo | null = null

  title: string = 'Recommendations'

  // recommendationData: any = {
  //   reason: "These courses are relevant to Bruce's role as a Syneos Manager, align with his core competencies and confidence levels, and are not yet completed.",
  //   recommendations: [
  //     {
  //       course: "AM Engaging The C-Suite",
  //       courseId: "18fa5783-a9ad-4a4c-887f-df91e7f1af33",
  //       weight: 90
  //     },
  //     {
  //       course: "SE Strategic Business Planning",
  //       courseId: "d72905ec-e16f-40db-a10d-a385ba444b02",
  //       weight: 85
  //     },
  //     {
  //       course: "AM Medical Loss Ratio",
  //       courseId: "1c4d38a1-0062-463c-90b2-ab70492e049a",
  //       weight: 80
  //     },
  //     {
  //       course: "AM Preventive Care",
  //       courseId: "65bd6ce4-9015-46dd-9f5a-228ebdab9a84",
  //       weight: 75
  //     },
  //     {
  //       course: "PR-102.01 Copay Accumulator and Maximizer Brochure - Digital",
  //       courseId: "31560627-644a-4e83-a543-dfac7bf8397b",
  //       weight: 70
  //     }
  //   ]
  // }

  recommendations: RecommendationItem[] = []
  nudges: Nudge = {}
  today = new Date()

  constructor(
    private http: HttpClient,

    private nativeHTTP: HTTP,
    private linkService: DelphireLinkService,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private fbS: FirebaseService,
    private delphireApi: DelphireApiService
  ) {}

  ngOnInit(): void {
    const { user } = this.localStorage.get('delphireUser')
    const tenantId = this.localStorage.get('currentSpace').id
    const delphireApi = this.delphireApi
    
    // eslint-disable-next-line @typescript-eslint/no-this-alias

    let self = this

    setTimeout(() => {
      self.loadRecommendations()
    }, 100)

  }

  loadRecommendations(): void {

    const { user } = this.localStorage.get('delphireUser')

    console.log('%c current user (recommendations):', 'color:red')
    console.log(user)

    
    if (this.widget != null && this.widget.parameters) {
      console.log('%c FOUND RECOMMENDATIONS WIDGET:', 'color:cyan')
      console.log(this.widget)

      let tileTitle: string | undefined = this.widget.parameters.title
      if (tileTitle != undefined) {
        this.title = tileTitle
      }
    }

    let data = {
      "first_name": user.givenName,
      "last_name": user.familyName,
      "email": user.email,
      "role": "Syneos"
    }

    // let data = {
    //   "first_name": 'Bruce',
    //   "last_name": 'Hubbard',
    //   "email": 'bruce.hubbard@syneoshealth.com',
    //   "role": "Syneos"
    // }

    try {
      const recommendationsApiUrl = environment.recommendationsApiUrl
      this.http.post<any>(recommendationsApiUrl, data).subscribe((response) => {
        console.log('%c Recommendations Response:', 'color:cyan')
        console.log(response)
        this.recommendationData = response
        this.loading = false

        // this.recommendationData!.recommendations = []

        if (this.recommendationData != null) {
          if (this.recommendationData.recommendations. length > 0) {
            this.recommendationData.scrollText = "Scroll here to view recommended items made specifically for you. Recommendations update every 24 hours. Check back daily for updates.";
            this.recommendationData.lastUpdatedText = "Last updated yesterday at 11:00PM (" +  moment.tz.guess() + ")";
            this.getExtraRecommendationsData()
          } else {
            this.recommendationData.scrollText = "No recommendations available..."
          }
        }
      })
    } catch (exception: any) {
      // say something about error
      this.loading = false
    }
  }

  getExtraRecommendationsData(): void {
    if (this.recommendationData == null) {
      this.delayLoadingSlightly()
      return
    }
    
    if (this.recommendationData.recommendations.length <= 0) {
      this.delayLoadingSlightly()
      return
    }

    const tenantId = this.localStorage.get('currentSpace').id
    const userId = this.localStorage.get('delphireUser').user.id

    let loadingRecommendationsCount = this.recommendationData.recommendations.length

    for (let i = 0; i < this.recommendationData.recommendations.length; i++) {
      let oneRecommendationEntry = this.recommendationData.recommendations[i]
      let courseId = oneRecommendationEntry.courseId
      const { databaseUrl } = this.localStorage.get('currentSpace')

      // this.delphireApi
      //   .postRequestPrisma('/iam/courses', {
      //     tenantId,
      //     id: courseId
      //   })
      //   .then((course: any) => {
      //     course.subscribe((course: any) => {
      //       console.log('%cCOURSE EXTRA DATA: ', 'color:red')
      //       console.log(course)
      //     })
      //   })

      // this.delphireApi
      //   .postRequestPrisma('/iam/registrations', {
      //     tenantId: tenantId,
      //     // tenantId: environment.globalScormTenantId,
      //     id: courseId,
      //     learnerId:userId
      //   })
      //   .then((result: any) => {
      //     result.subscribe((data: any) => {
      //       console.log('%cCOURSE EXTRA DATA: ', 'color:red')
      //       console.log(data)
      //     })
      //   })

      this.delphireApi
        .postRequestPrisma('/iam/registrations',{tenantId, courseId: courseId, learnerId:userId})
          .then((res)=>{
            res.subscribe((data)=>{
              console.log('%cCOURSE REGISTRATION DATA: ', 'color:#00FF00')
              console.log(data)

              loadingRecommendationsCount--
              console.log('%cRemaining recos: ' + loadingRecommendationsCount, 'color:red')
              if (loadingRecommendationsCount <= 0) {
                this.loading = false
              }

              if (data.body.registrations == null || data.body.registrations.length <= 0) {
                this.removeItemFromRecommendationsList(courseId)
                return
              }
              
              if (data.body.registrations.length > 0 && data.body.registrations[0].activityDetails) {
                let index = data.body.registrations.length - 1
                console.log(data.body.registrations[index].activityDetails.activityCompletion)
                
                if (data.body.registrations[index].activityDetails.activityCompletion === 'COMPLETED') {
                  this.removeItemFromRecommendationsList(data.body.registrations[index].course.id)
                } else if (data.body.registrations[index].activityDetails.completionAmount.scaled <= 0) {
                  this.updateRecommendationItemProgressToNothing(data.body.registrations[index].course.id)
                } else if (data.body.registrations[index].activityDetails.completionAmount.scaled < 1) {
                  this.updateRecommendationItemProgressToInProgress(data.body.registrations[index].course.id)
                }
              }
              
            })
          })
    }
  }

  getStatusBlockClass(recommendation: RecommendationItem): string {
    if (recommendation.progressStatus === 'Not Started') {
      return "not-started"
    }
    return ""
  }

  clickItem(recommendation: RecommendationItem): void {
    this.linkService.handleLink(
      {
        id: recommendation.courseId,
        type: 'course'
      }
    )
  }

  clickRefresh(): void {
    this.loading = true
    this.getExtraRecommendationsData()
  }

  removeItemFromRecommendationsList(courseId: string): void {
    let recommendationItemToRemove = this.recommendationData?.recommendations
      .filter(theRecommendation => theRecommendation.courseId === courseId)[0]

    if (recommendationItemToRemove == null) {
      return
    }

    let index: number | undefined = this.recommendationData?.recommendations.indexOf(recommendationItemToRemove)
    if (index != undefined && index > -1) {
      console.log('%c REMOVED ITEM FROM RECOMMENDATIONS LIST: ' + courseId, 'color: red')
      this.recommendationData?.recommendations.splice(index, 1)
    }
  }

  updateRecommendationItemProgressToNothing(courseId: string): void {
    let recommendationItemToUpdate = this.recommendationData?.recommendations
      .filter(theRecommendation => theRecommendation.courseId === courseId)[0]

    if (recommendationItemToUpdate == null) {
      return
    }

    recommendationItemToUpdate.progressStatus = 'Not Started'
  }

  updateRecommendationItemProgressToInProgress(courseId: string): void {
    let recommendationItemToUpdate = this.recommendationData?.recommendations
      .filter(theRecommendation => theRecommendation.courseId === courseId)[0]

    if (recommendationItemToUpdate == null) {
      return
    }

    recommendationItemToUpdate.progressStatus = 'In Progress'
  }

  delayLoadingSlightly(): void {
    setTimeout(() => {
      this.loading = false
    }, 250)
  }

}
