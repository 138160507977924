import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AuthService } from '@auth0/auth0-angular'
import { Capacitor } from '@capacitor/core'
import { Observable } from 'rxjs'
import { v4 as uuidv4 } from 'uuid'
import { environment } from '../../environments/environment'
import { LocalStorageService } from './local-storage-service.service'
// const SHARP_ENDPOINT = 'http:localhost:3000'
@Injectable({
  providedIn: 'root'
})
export class DelphireApiService {
  authToken: string | null = null

  public getApiToken(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.auth.getAccessTokenSilently().subscribe((token) => {
        this.authToken = token
        resolve()
      })
    })
  }
//   async longPoll$ (channelId: string) {
//     const controller = new AbortController()
//     const {signal} = controller
//     return new Observable((subscriber => {
//         const poll = () => {
//             const url = `${SHARP_ENDPOINT}/events/${channelId}`
//         this.postRequest(url, {signal} )
//         .then(response => response.json())
//         .then(event => {
//             subscriber.next(event)
//             poll()
//         })
//         .catch(err => {
//             poll()
//         })
//     }
//     poll()
//     return () => controller.abort()
//     }))
// }
  async postScormRequest(
    method: string,
    params: object
  ): Promise<Observable<HttpResponse<any>>> {
    await this.getApiToken()
    const headers = {
      'Authorization': 'Bearer ' + this.authToken,
      'Content-Type': 'application/json'
    }

    const requestUrl = environment.scormEngine + '/api'
    const data = {
      method: method,
      params: params,
      jsonrpc: '2.0',
      id: uuidv4()
    }
    return this.http.post<any>(requestUrl, data, {
      headers: headers,
      observe: 'response',
      responseType: 'json'
    })
  }
  async postRequestPrisma(
    method: string,
    params: object
  ): Promise<Observable<HttpResponse<any>>> {
    await this.getApiToken()
    const headers = {
      'Authorization': 'Bearer ' + this.authToken,
      'Content-Type': 'application/json'
    }
    //TODO: DEAL WITH THIS
    let apiUrl = environment.authConfig.audience
    if(Capacitor.isNativePlatform()) {
      apiUrl = environment.authConfig.audience
    }
    const requestUrl = apiUrl + method
    const data = {
      ...params
    }
    return this.http.post<any>(requestUrl, data, {
      headers: headers,
      observe: 'response',
      responseType: 'json'
    })
  }
  async postRequest(
    method: string,
    params: object
  ): Promise<Observable<HttpResponse<any>>> {
    await this.getApiToken()
    const headers = {
      'Authorization': 'Bearer ' + this.authToken,
      'Content-Type': 'application/json'
    }
    const { apiUrl } = this.localStorage.get('currentSpace')
    const requestUrl = apiUrl + method
    const data = {
      params: params
    }
    return this.http.post<any>(requestUrl, data, {
      headers: headers,
      observe: 'response',
      responseType: 'json'
    })
  }
  async getRequest(
    method: string,
    params: HttpParams
  ): Promise<Observable<HttpResponse<any>>> {
    await this.getApiToken()
    const headers = {
      'Authorization': 'Bearer ' + this.authToken,
      'Content-Type': 'application/json'
    }
    const { apiUrl } = this.localStorage.get('currentSpace')
    const requestUrl = apiUrl + method
    return this.http.get<any>(requestUrl, { params: params })
  }
  constructor(
    private auth: AuthService,
    private http: HttpClient,
    private localStorage: LocalStorageService
  ) {}
}
