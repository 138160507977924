import { Injectable } from "@angular/core"
import { Capacitor } from "@capacitor/core"
import { Observable } from "rxjs/internal/Observable"
import { environment } from "src/environments/environment"

let SHARP_ENDPOINT = location.hostname === 'localhost' ? 'http://localhost:3000' : environment.authConfig.audience
if(Capacitor.isNativePlatform()) {
    SHARP_ENDPOINT = environment.authConfig.audience
}
@Injectable({
    providedIn: 'root'
})
export class LongPollingService {
    constructor() {}
    
    longPoll$ (channelId: string) {
    const controller = new AbortController()
    const {signal} = controller
    return new Observable((subscriber => {
        const poll = () => {
        const url = `${SHARP_ENDPOINT}/events/${channelId}`
        fetch(url, {signal} )
        .then(response => response.json())
        .then(event => {
            subscriber.next(event)
            poll()
        })
        .catch(err => {
            poll()
        })
    }
    poll()
    return () => controller.abort()
    }))
}
}