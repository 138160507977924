import { Component, EventEmitter, Input, OnInit } from '@angular/core'
import { cloneDeep } from 'lodash'

import { Task } from '../../../app/interfaces/task'
import { environment } from '../../../environments/environment'
import { RoadmapLevel } from '../interfaces/roadmap-level'

@Component({
  selector: 'roadmap-custom',
  templateUrl: './custom.component.html',
  styleUrls: ['./custom.component.sass']
})
export class CustomRoadmap implements OnInit {
  @Input() roadmap: any
  @Input() taskLookup: any
  @Input() roadmapReady!: EventEmitter<boolean>

  verbose: boolean = environment.production ? false : true

  currentLevel: RoadmapLevel = <RoadmapLevel>{}
  currentSubLevel: RoadmapLevel = <RoadmapLevel>{}

  levels: RoadmapLevel[] = []
  sublevels: RoadmapLevel[] = []
  levelLookup = <any>{}
  sublevelsLookup = <any>{}

  currentPrimaryId: string = ''
  currentSecondaryId: string = ''
  tasks: Task[] = []
  filteredTasks: Task[] = []

  filterValue: string = ""

  constructor() {}

  //~ ------------------------------------ INIT ------------------------------------

  ngOnInit(): void {
    if (this.roadmapReady) {
      this.init()
      this.roadmapReady.subscribe((ready) => {
        if (this.roadmap.levels) {
          this.init()
        }
      })
    }
  }

  init(): void {
    if (this.verbose) {
      console.log('%c[ CUSTOM ROADMAP INIT ]', 'color: deeppink')
    }
    this.createLevels()
    this.clickFirstLevels()
  }

  //~ ------------------------------------ FILTER ------------------------------------

  simpleTextFilter = (arr:Task[], text:string) => {
    return arr.filter((item) => {
      return (item && item.name && item.name?.toLowerCase().indexOf(text) != -1) ||
        (item && item.description && item.description?.toLowerCase().indexOf(text) != -1)
    }
  )}

  applyFilter() {
    console.log( '%c[ applyFilter ]', 'color: red')
    let filterValueCleaned:string = this.filterValue.trim().toLowerCase()

    console.log( '%c[ applyFilter ] filterValueCleaned', 'color: red', filterValueCleaned )
    console.log( '%c[ applyFilter ] this.tasks', 'color: red', this.tasks )

    // filter tasks by keyword
    this.filteredTasks = this.simpleTextFilter(this.tasks, filterValueCleaned)

    console.log( '%c[ applyFilter ] this.filteredTasks', 'color: red', this.filteredTasks )
  }

  //~ ------------------------------------ CLICKS ------------------------------------

  onPrimaryChange(): void {
    this.currentLevel = this.levelLookup[this.currentPrimaryId]
    switch (this.roadmap.levels?.type) {
      case 'single':
        if (this.currentLevel.tasks) {
          this.tasks = cloneDeep(this.currentLevel.tasks)
          this.applyFilter()
        }
        break

      case 'double':
        if (this.verbose) {
          console.log( '%c[ this.currentPrimaryId ]', 'color: lime', this.currentPrimaryId )
        }
        if (this.verbose) {
          console.log( '%c[ this.currentSecondaryId ]', 'color: lime', this.currentSecondaryId )
        }
        if (this.verbose) {
          console.log( '%c[ this.currentLevel ]', 'color: yellow', this.currentLevel )
        }

        this.onSecondaryChange()

        break
      default:
        break
    }
  }

  onSecondaryChange(): void {
    this.filteredTasks =
      this.currentLevel.sublevelsLookup[this.currentSecondaryId].tasks
    this.currentSubLevel =
      this.currentLevel.sublevelsLookup[this.currentSecondaryId]

    if (this.verbose) {
      console.log(
        '%c[ onSecondaryChange ] ( this.currentSecondaryId )',
        'color: orange',
        this.currentSecondaryId
      )
    }
    if (this.verbose) {
      console.log(
        '%c[ onSecondaryChange ] ( this.filteredTasks )',
        'color: orange',
        this.filteredTasks
      )
    }
    if (this.verbose) {
      console.log(
        '%c[ onSecondaryChange ] ( this.currentSubLevel )',
        'color: orange',
        this.currentSubLevel
      )
    }
  }

  // ----------------------------------------------

  clickFirstLevels(): void {
    if (this.roadmap.levels && this.levels) {
      this.currentPrimaryId = this.levels[0].id
      this.onPrimaryChange()
    }
  }

  // ----------------------------------------------

  createLevelsSecondary(): void {
    if (!this.roadmap.levels.secondary) return

    this.sublevels = []
    this.sublevelsLookup = []

    for (let i = 0; i < this.roadmap.levels.secondary.order.length; i++) {
      let id = this.roadmap.levels.secondary.order[i]
      let level = this.roadmap.levels.secondary.collection[id]
      level.id = id
      level.order = i
      this.sublevels.push(level)
      this.sublevelsLookup[id] = level
    }

    // this.sublevelsLookup is basically a clone of roadmap.levels.secondary.collection with added id and order
    // this.sublevels is the same thing but an array

    this.sublevels.sort((a: any, b: any) => (a.order > b.order ? 1 : -1))

    if (this.verbose) {
      console.log('%c[ this.sublevels ]', 'color: cyan', this.sublevels)
    }
    if (this.verbose) {
      console.log(
        '%c[ this.sublevelsLookup ]',
        'color: cyan',
        this.sublevelsLookup
      )
    }
  }

  // ---------------------------------

  createLevels(): void {
    // reset all levels
    this.levels = []

    // loop through all roadmap levels, in order
    this.roadmap.levels.primary.order.forEach((id: string, i: number) => {
      // start working on a level
      const level: RoadmapLevel = this.roadmap.levels.primary.collection[id]

      // add a few properties we need later
      level.id = id
      level.order = i

      // if we have any tasks in this roadmap
      if (this.roadmap.levels?.tasks && this.roadmap.levels?.tasks[id]) {
        switch (this.roadmap.levels?.type) {
          // ---------------------------------

          case 'single':
            const _tasks: Task[] = []
            Object.values(this.roadmap.levels?.tasks[id]).forEach(
              (taskId: any) => {
                const _task: Task = this.taskLookup.all[taskId]
                _tasks.push(_task)
              }
            )
            level.tasks = _tasks
            // turned off this sort b/c results were appearing in reverse order
            // level.tasks.sort((a:any, b:any) => (a.order > b.order) ? 1 : -1)
            break

          // ---------------------------------

          case 'double':
            this.createLevelsSecondary()

            let _sublevels = []
            let _sublevelsLookup = <any>{}
            for (let sublevelId in this.roadmap.levels.tasks[id]) {
              let sublevelTasks = this.roadmap.levels.tasks[id][sublevelId] // ['id', 'id', 'id']
              let _sublevel = cloneDeep(this.sublevelsLookup[sublevelId])
              _sublevel.id = sublevelId
              _sublevel.tasks = []
              _sublevelsLookup[sublevelId] = {
                tasks: []
              }
              for (let j = 0; j < sublevelTasks.length; j++) {
                let taskId = sublevelTasks[j] // "1565b40e-2129-4bd8-8ce9-ca784d4c4e16"
                _sublevel.tasks.push(this.taskLookup.all[taskId])
              }
              _sublevels.push(_sublevel)
              _sublevelsLookup[sublevelId] = _sublevel
            }

            _sublevels.sort((a: any, b: any) => (a.order > b.order ? 1 : -1))

            level.sublevels = cloneDeep(_sublevels)
            level.sublevelsLookup = cloneDeep(_sublevelsLookup)

            // if(this.verbose) { console.log('%c[ this.sublevelsLookup ]', 'background-color: red', this.sublevelsLookup) }
            // if(this.verbose) { console.log('%c[ level.sublevelsLookup ]', 'background-color: red', level.sublevelsLookup) }

            level.sublevels.sort((a: any, b: any) =>
              a.order > b.order ? 1 : -1
            )

            break

          // ---------------------------------

          default:
            break
        }
      }
      this.levels.push(level)
      this.levelLookup[id] = level
    })

    this.currentLevel = this.levels[0]

    if (this.currentLevel.id) this.currentPrimaryId = this.currentLevel.id
    if (this.roadmap.levels?.type == 'double')
      this.currentSecondaryId = this.currentLevel.sublevels[0].id
    if (
      this.currentLevel &&
      this.currentSecondaryId != '' &&
      this.currentLevel.sublevelsLookup[this.currentSecondaryId]
    ) {
      this.tasks = this.currentLevel.sublevelsLookup[this.currentSecondaryId].tasks
      this.applyFilter()
    }
  }
}
