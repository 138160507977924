import { Component, Input, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { environment } from '../../environments/environment'
import { Resource } from '../interfaces/resource'
import { Widget } from '../interfaces/widget'
import { DelphireApiService } from '../services/delphire-api.service'
import { DelphireLinkService } from '../services/delphire-link.service'
import { FirebaseService } from '../services/firebase.service'
import { LocalStorageService } from '../services/local-storage-service.service'
import { Logger } from '../services/logger.service'


@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.sass']
})
export class WidgetComponent implements OnInit {
  @Input()
  widget: Widget = {
    h: 0,
    i: '',
    id: '',
    minH: 0,
    minW: 0,
    moved: false,
    static: false,
    w: 0,
    x: 0,
    y: 0,
    position: {
      x: 0,
      y: 0
    },
    size: {
      width: 0,
      height: 0
    },
    style: {},
    type: '',
    roadmapTitle: undefined,
    description: '',
    disabled: false
  }

  background: string = 'none'

  roadmapTitle: string | undefined = undefined

  getTextColor(): string {
    if (this.widget.style && this.widget.style.textColor) {
      return this.widget.style.textColor
    } else {
      return '#000'
    }
  }

  getFontStyle(): string {
    if(this.widget.style.titleFont){
      return  this.widget.style.titleFont;
    }
    return '';
  }

  getFontPlacement(): string {
    let fontStyle = 'widget-centered'
    if(this.widget.style.fontPlacement){
      if(this.widget.style.fontPlacement === 'center'){
        fontStyle = 'widget-centered';
      }else {
        fontStyle = `widget-${this.widget.style.fontPlacement}`;
      }
    }

    if(this.widget.style.fontAlignment){
      fontStyle = `${fontStyle} widget-${this.widget.style.fontAlignment}`
    }
    return fontStyle;
  }

  getResource(id: string): Promise<Resource> {
    return new Promise((resolve, reject) => {
      const { databaseUrl } = this.localStorage.get('currentSpace')
      this.fbs
        .db(databaseUrl)
        .object(environment.firebasePaths.space + 'resources/' + id)
        .subscribe((resource: Resource) => {
          if (resource) return resolve(resource)
          else return reject('Failed to fetch resource')
        })
    })
  }
  selectWidget() {
    let id: string | undefined = undefined
    console.log('widget params', this.widget.parameters)
    if (this.widget.parameters) {
      id = Object.values(this.widget.parameters).toString()
    }
    const type: string = this.widget.type.toLocaleLowerCase()

    switch (type) {
      case 'roadmap':
      case 'library':
      case 'course':
      case 'quiz':
      case 'verbalizer':
      case 'agenda':
        this.linkService.handleLink({ type: type, id: id, route: this.route })
        break
      case 'external link':
        this.linkService.handleLink({
          type: 'externallink',
          id: id,
          parameters: {
            externalLinkId: id
          },
          resource: {
            id: id,
            type: 'externallink'
          }
        })
        break
      case 'resource':
        if (id) {
          this.getResource(id).then((resource) => {
            this.linkService.handleLink({
              type: resource.type,
              route: this.route,
              resource
            })
          })
        }
        break
      default:
        break
    }
  }

  getDescriptionColor(): string {
    if (this.widget.style && this.widget.style.descriptionColor) {
      return this.widget.style.descriptionColor
    } else {
      return '#000'
    }
  }
  getIcon(): string {
    if (this.widget.iconResourceId) {
      if (this.widget.iconResourceId.indexOf('icon') > -1) {
        return 'da-2x ' + this.widget.iconResourceId
      } else {
        return 'fa fa-2x fa-' + this.widget.iconResourceId
      }
    } else {
      return 'none'
    }
  }

  getIconPlacement(): string {
    // console.log("this.widget            -> ", this.widget);
    if(this.widget.style.iconPlacement) {
      switch(this.widget.style.iconPlacement){
        case 'top-left':
          return 'widget-icon top left'
        case 'top-center':
          return 'widget-icon top horizontal-center'
        case 'top-right':
          return 'widget-icon top right'
        case 'bottom-left':
          return 'widget-icon bottom left'
        case 'bottom-center':
          return 'widget-icon bottom horizontal-center'
        case 'bottom-right':
          return 'widget-icon bottom right'
        case 'center':
          return 'widget-icon center horizontal-center'
        default:
          return 'widget-icon'
      }
    } else {
      return "widget-icon"
    }
  }

  getLabelSize(): string {
    if(this.widget.style.titleSize){
      return this.widget.style.titleSize;
    }
    return '0.7em';
  }

  getTitleStyle(): string {
    if(this.widget.style.titleStyle){
      return `widget-label ${this.widget.style.titleStyle}`
    }
    return "widget-label"
  }

  getBackground(): void {
    if (this.widget.style && this.widget.style.backgroundResourceId) {
      this.delphireAPI
        .postRequest('resources/getDownloadUrl', {
          id: this.widget.style.backgroundResourceId
        })
        .then((response) => {
          response.subscribe((url) => {
            this.background = "url('" + url.body.result.url + "')"
          })
        })
    }
  }
  getBackgroundColor(): string {
    if (this.widget.style && this.widget.style.backgroundColor) {
      if (this.widget.style.opacity) {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
          this.widget.style.backgroundColor
        )
        if (result) {
          return (
            'rgba(' +
            parseInt(result[1], 16) +
            ', ' +
            parseInt(result[2], 16) +
            ', ' +
            parseInt(result[3], 16) +
            (', ' + this.widget.style.opacity + ')')
          )
        } else {
          return this.widget.style.backgroundColor
        }
      } else {
        return this.widget.style.backgroundColor
      }
    } else {
      return 'transparent'
    }
  }
  constructor(
    private delphireAPI: DelphireApiService,
    private logger: Logger,
    private router: Router,
    private route: ActivatedRoute,
    private linkService: DelphireLinkService,

    private localStorage: LocalStorageService,

    private fbs: FirebaseService
  ) {}

  ngOnInit(): void {
    this.getBackground()
  }
}
